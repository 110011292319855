<template>
  <div  v-if="teams.length > 0">
    <h1 class="text-center my-5 text-white">Judge {{ judgeID }} View</h1>
    <div class="d-flex justify-content-center align-items-center my-3">
      <div class="increment-button" @click="stepBackHeat">-</div>
      <h2 class="text-white mx-0">Current Heat: {{ judgingHeat }}</h2>
      <div class="increment-button" @click="stepForwardHeat">+</div>
    </div>
    <HeatInTheWater :heatNumber="judgingHeat" :judgeID = judgeID />
    <HeatInTheWater v-if="judgingHeat > 16 && judgingHeat < 44" :heatNumber = "judgingHeat + 1" :judgeID = judgeID />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HeatInTheWater from '@/components/master/HeatInTheWater'
export default {
  name: 'JudgeHome',
  components: {
    HeatInTheWater
  },
  props: {
    judgeID: {
      type:[ Number, String ]
    }
  },
  data() {
    return {
      judgingHeat: 1
    }
  },
  beforeMount(){
    if(!this.$store.state.loggedIn  && this.$store.state.role != 'Judge1') {
      this.$router.push({name: 'Home'})
    }
  },
  mounted() {
      if (this.teams.length<1) {
        this.$store.dispatch('getTeams')
      }
      if(this.heats.length < 1 ) {
        this.$store.dispatch('getHeats')
      }
      if(this.surfers.length < 1 ) {
        this.$store.dispatch('getSurfers')
      }
      this.setCurrentHeat()
    },
    methods: {
      async setCurrentHeat() {
        await this.$store.dispatch('getLiveHeat')
        this.judgingHeat = this.liveHeat
      },
      stepForwardHeat() {
        // make sure judge has signed off on scores before advancing
        if ( this.thisHeat.judges_ready[this.judgeID - 1] ) {
          this.$store.dispatch('getHeats')
          this.judgingHeat ++;
        } else {
          alert('You need to finish your scores before advancing.')
        }
      },
      stepBackHeat() {
        this.judgingHeat --;
      }
    },
    computed: {
      thisHeat() {
        return this.heats.find(heat => heat.heat_number == this.judgingHeat)
      },
      ...mapState({
        surfers: state => state.surfers.surfers,
        teams: state => state.teams.teams,
        heats: state => state.heats.heats,
        currentHeat: state => state.heats.currentHeat,
        liveHeat: state => state.heats.liveHeat,
        admin: state => state.admin,
        winningTeam: state => state.heats.winningTeam
    })
  }
}
</script>

<style>
.increment-button {
  background: orange;
  padding: .5rem 2rem;
  margin: .5rem;
}
</style>