<template>
  <div class="jersey-color-container text-center">
    <img v-if="!hidden" class="jersey-color-icon" :class="{'small' : small}" :src="jerseyColor" alt="">
  </div>
</template>

<script>
  export default {
    props: {
      color: String,
      small: Boolean,
    },
    data() {
      return {
        hidden: false
      }
    },
    computed: {
      jerseyColor() {
        if(this.color) {
          return require('@/assets/jerseys/' + this.color + '.svg')
        } else {
          this.hidden = true
          return ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.jersey-color-container {
  position: relative;
}
.jersey-color-icon {
  height: 50px;
  width: 50px;
  margin: 1rem;
  &.small {
    margin: 2px;
    height: 20px;
    width: 20px;
  }
}
</style>