<template>
  <div class="score-input" :class="{ 'submitted': submitted, 'needs-score': !emptyScore }">
    <p class="text-center">Wave {{ score.wave_id }} </p>
    <div class="d-flex align-items-end">
      <div>
        <ScoreCard :score="score" :surferNumber="surferNumber" :heatTeam="heatTeam" :heatNumber="heatNumber" :judgeID = "judgeID" @submit="updateSubmit()"/>
      </div>
      <!-- <div v-show="!submitted">
        <div ref="submitButton" class="btn btn-primary btn-sm" @click="addScore()">Submit</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import ScoreCard from '@/components/master/ScoreCard.vue';
export default {
  name: "JudgeScoreInput",
  props: {
    surferNumber: {
      type: [Number, String]
    },
    heatTeam: {
      type: [Number, String]
    },
    heatNumber: {
      type: [ Number, String ]
    },
    judgeID: {
      type: [ Number, String ]
    },
    score: {
      type: Object
    }
  },
  components: {
    ScoreCard
  },
  data() {
    return {
      newScore: "",
      submitted: false
    }
  },
  beforeMount() {
    this.newScore = this.score.scores[this.judgeID - 1]
    if ( this.newScore != 0 ) {
      this.submitted = true
    }
  },
  computed: {
    emptyScore() {
      let isEmpty = true;
      this.score.scores.forEach( (score) => {
        if ( score != 0 && !this.submitted ) {
          isEmpty = false;
        }
      })
      return isEmpty;
    }
  },
  methods: {
    updateSubmit() {
      console.log('submitted');
      this.submitted = true;
    }
  }
  // scores will be displayed, if the input is focused, it will show the submit button.
  // when the submit button is clicked, it will dispatch the "addScore" action, just with an updated score for whatever judge this is
  // need to add a prop for the existing score object, and we will just modify that with this judge's updatd score
  // should be a conditional class for any score with a value of 0 that makes it red or something to indicate a score is needed
  // scores that have already been submitted will be editable, but they should look set
}
</script>

<style lang="scss" scoped>
.score-input {
  background: rgb(202, 202, 202);
  padding: 1rem;
  margin: .5rem;
  &.needs-score {
    background: rgb(238, 232, 111);
  }
  &.submitted {
    background: rgb(196, 236, 187);
  }
}
p {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: .5rem;
  font-weight: bold;
}

input {
  width: 75px;
  text-align: center;
  margin: 0;
  margin-right: .25rem;
  padding: .1rem;
}
</style>