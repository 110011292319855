import Vue from "vue";
import Vuex from "vuex"; 
import HttpService from '../services/HttpService'

Vue.use(Vuex);


const surferModule = {
  state: {
    surfers: []
  },
  mutations: {
    SET_SURFERS(state, surfers) {
      state.surfers = surfers
    }
  },
  actions: {
    createSurfer({commit}, payload) {
      HttpService.createSurfer(payload).then(() => {
        return HttpService.getSurfers().then((response) => {
          commit('SET_SURFERS', response.data.surfers)
        })
      })
    },
    saveSurfer({commit}, payload) {
      HttpService.saveSurfer(payload).then(() => {
        return HttpService.getSurfers().then((response) => {
          commit('SET_SURFERS', response.data.surfers)
        })
      })
    },
    getSurfers({commit}) {
      return HttpService.getSurfers().then((response) => {
        commit('SET_SURFERS', response.data.surfers)
      })
    },
    deleteSurfer({commit}, payload) {
      HttpService.deleteSurfer(payload).then(() => {
        return HttpService.getSurfers().then((response) => {
          commit('SET_SURFERS', response.data.surfers)
        })
      })
    }
  }
}

const teamModule = {
  state: {
    teams: []
  },
  mutations: {
    SET_TEAMS(state, teams) {
      // have to do teams.teams because the database object teams is returned, so this saves future filtering
      state.teams = teams.teams
    },
    UPDATE_TEAM(state, team) {
      let teamIndex = state.teams.findIndex(targetTeam => targetTeam.team_id === team.team_id)
      Vue.set(state.teams, teamIndex, team)
    }
  },
  actions: {
    initTeams({commit}) {
      console.log('initializingn teams');
      let initTeams = []
      for (var i = 1; i <= 48; i++) {
        let newTeam = {
          "team_id": i,
          "surfer_1_id": i + (i-1),
          "surfer_2_id": i + i
        }
        initTeams.push(newTeam)
      }
      console.log(initTeams);
      return HttpService.initTeams(initTeams).then((response) => {
        console.log(response.data);
        commit('SET_TEAMS', response.data)
      })
    },
    getTeams({commit}) {
      return HttpService.getTeams().then((response) => {
        commit('SET_TEAMS', response.data)
      })
    },
    updateTeam({commit}, payload) {
      //payload contains new team object
      return HttpService.updateTeam(payload).then((response) => {
        commit('UPDATE_TEAM', response.data.team)
      })
    }
  }
}

const heatModule = {
  state: {
    currentHeat: 0,
    liveHeat: 0,
    heats: [],
    winningTeam: 0
  },
  mutations: {
    SET_HEATS(state,heats) {
      state.heats = heats.heats
    },
    SET_CURRENT_HEAT(state, contest) {
      state.currentHeat = contest.contest.currentHeat
    },
    SET_LIVE_HEAT(state, contest) {
      state.liveHeat = contest.contest.liveHeat
    },
    UPDATE_HEAT(state, heat) {
      // find the heat to be updated
      let heatIndex = state.heats.findIndex(targetHeat => targetHeat.heat_number === heat.heat_number)
      // replace with new heat object with updated scores
      // using Vue.set to make sure vue detects the update to the array
      Vue.set(state.heats, heatIndex, heat)
    },
    END_CONTEST (state, contest) {
      state.winningTeam = contest.contest.winningTeam
    }
  },
  actions: {
    async initContest({commit}) {
      await HttpService.initContest()
      return HttpService.getHeats().then((response) => {
        commit('SET_HEATS', response.data)
      })
    },
    getWinningTeam({commit}) {
      return HttpService.getCurrentHeat().then((response) => {
        commit('END_CONTEST', response.data)
      })
    },
    endContest({commit}, winningTeam) {
      return HttpService.endContest({winningTeam: winningTeam}).then((response) => {
        commit('END_CONTEST', response.data)
      })
    },
    getHeats({commit}) {
      return HttpService.getHeats().then((response) => {
        commit('SET_HEATS', response.data)
      })
    },
    getCurrentHeat({commit}) {
      return HttpService.getCurrentHeat().then((response) => {
        commit('SET_CURRENT_HEAT', response.data)
      }) 
    },
    getLiveHeat({commit}) {
      return HttpService.getCurrentHeat().then((response) => {
        commit('SET_LIVE_HEAT', response.data)
      }) 
    },
    getNextHeat({commit, state}) {
      let nextHeat = state.currentHeat + 1
      return HttpService.nextHeat({nextHeat: nextHeat}).then((response) => {
        commit('SET_CURRENT_HEAT', response.data)
      })
    },
    getNextLiveHeat({ commit, state}) {
      let nextLiveHeat = state.liveHeat + 1
      return HttpService.advanceLiveHeat({ nextLiveHeat: nextLiveHeat }).then((response) => {
        commit('SET_LIVE_HEAT', response.data)
      })
    },
    getPreviousHeat({commit, state}) {
      let nextHeat = state.currentHeat - 1
      return HttpService.nextHeat({nextHeat: nextHeat}).then((response) => {
        commit('SET_CURRENT_HEAT', response.data)
      })
    },
    getNextTwoHeats({commit, state}) {
      let nextHeat = state.currentHeat + 2
      return HttpService.nextHeat({nextHeat: nextHeat}).then((response) => {
        commit('SET_CURRENT_HEAT', response.data)
      })
    },
    updateHeatId({commit}, payload) {
      // payload is object that includes the heat_number and the heat object
      return HttpService.updateHeatId(payload).then((response) => {
        commit('UPDATE_HEAT', response.data.heat)
      })
    },
    async doneJudging( { commit, state }, payload ) {
      // payload includes heat_number, judgeID, judge_ready

      // grab the target heat from the db
      let targetHeat = {}
      await HttpService.getHeatId(payload).then((response) => {
        targetHeat = response.data.heat
      })

      targetHeat.judges_ready[payload.judgeID - 1] = payload.judge_ready

      // if all judges are ready, advance the live scoring view
      if (targetHeat.judges_ready[0] && targetHeat.judges_ready[1] && targetHeat.judges_ready[2] ) {
        // get current live heat
        await HttpService.getLiveHeat().then((response) => {
          commit('SET_LIVE_HEAT', response.data)
        })
        // this prevents judges from checking and unchecking the button and accidentally advancing multiple times
        if (targetHeat.heat_number == state.liveHeat ) {
          let nextLiveHeat = state.liveHeat + 1
          await HttpService.advanceLiveHeat({ nextLiveHeat: nextLiveHeat }).then((response) => {
            console.log(response.data)
            commit('SET_LIVE_HEAT', response.data)
          })
        }
      }

      // update heat object in db
      return HttpService.updateHeatId({heat_number: payload.heat_number, heat: targetHeat}).then((response) => {
        commit('UPDATE_HEAT', response.data.heat)
      })

    },
    async updateHeatTeam({ commit }, payload) {
      // payload includes heat_number, team_number (0,1,2), and team
      
      // grab the target heat from the db
      let targetHeat = {}
      await HttpService.getHeatId(payload).then((response) => {
        targetHeat = response.data.heat
      })

      // update the team in the heat
      targetHeat.teams[payload.team_number] = payload.team

      // update heat object in db
      return HttpService.updateHeatId({heat_number: payload.heat_number, heat: targetHeat}).then((response) => {
        commit('UPDATE_HEAT', response.data.heat)
      })
    },
    async updateHeatTeams({ commit }, payload) {
      // payload includes heat_number, team_number (0,1,2), and team
      
      // grab the target heat from the db
      let targetHeat = {}
      await HttpService.getHeatId(payload).then((response) => {
        targetHeat = response.data.heat
      })

      // update the team in the heat
      targetHeat.teams = payload.teams

      // update heat object in db
      return HttpService.updateHeatId({heat_number: payload.heat_number, heat: targetHeat}).then((response) => {
        commit('UPDATE_HEAT', response.data.heat)
      })
    },
    async updateScore({ commit, rootState }, payload ) {
      // payload will include heat_number, heatTeam, surferNumber, judgeID (if equal to 0, is master judge update), and newScore, and score
      let currentHeat = {} 
      let surfer = {}
      let surferObject = {}

      // find the heat to get the most recent updated scores
      await HttpService.getHeatId(payload).then((response) => {
        currentHeat = response.data.heat
        // Set the current team
        const team = currentHeat.teams[payload.heatTeam-1]
        
        // set the target surfer
        payload.surferNumber === "1" ? surfer = team.scores.surfer_1 : surfer = team.scores.surfer_2

        // find the surfer object in the surfers database so we can update their score averages
        const teamObject = rootState.teams.teams.find(teamInArray => teamInArray.team_id == team.team_id)
        payload.surferNumber === "1" ? surferObject = rootState.surfers.surfers.find(surfer => surfer.id == teamObject.surfer_1_id) : surferObject = rootState.surfers.surfers.find(surfer => surfer.id == teamObject.surfer_2_id)

        // replace the appropriate score with the new score - if master judge (0), replace whole score object
        if ( payload.judgeID === 0 ) {
          surfer.scores[payload.newScore.wave_id - 1] = payload.newScore
        } else {
          surfer.scores[payload.score.wave_id - 1].scores[payload.judgeID - 1] = payload.newScore
        }

        // check to see if all three scores are added
        let allScoresReady = true;
        surfer.scores[payload.score.wave_id - 1].scores.forEach(function(score) {
          score == 0 ? allScoresReady = false : null
        })

        if ( allScoresReady ) {
          // create wave average for this new wave
          const avgScore = (parseFloat(surfer.scores[payload.score.wave_id -1].scores[0]) + parseFloat(surfer.scores[payload.score.wave_id -1].scores[1]) + parseFloat(surfer.scores[payload.score.wave_id -1].scores[2]))/3
          const roundedScore = avgScore.toFixed(2)

          surfer.scores[ payload.score.wave_id -1 ].average = roundedScore

          // replace high score if applicable and recalculate team score
          
          // find the highest score in the scores array
          let newHighScore = 0;
          surfer.scores.forEach( (score) => {
            if ( score.average > newHighScore ) {
              newHighScore = score.average;
            }
          })
          surfer.high_score = newHighScore;

          const surfer_1_score = Math.round(team.scores.surfer_1.high_score * 100) / 100
          const surfer_2_score = Math.round(team.scores.surfer_2.high_score * 100) / 100

          team.scores.team_score = ((surfer_1_score) + (surfer_2_score)).toFixed(2);

          // update surfer stats

          // add score to list of scores
          if ( surferObject.all_scores ) {
            surferObject.all_scores.push(parseFloat(roundedScore))
          } else {
            surferObject.all_scores = []
            surferObject.all_scores.push(parseFloat(roundedScore))
          }

          // update average score
          let totalWaveScore = 0
          surferObject.all_scores.forEach(function(score) {
            totalWaveScore += score
          })
          surferObject.avg_score = totalWaveScore / surferObject.all_scores.length

          // update high score
          if (surferObject.high_score) {
            roundedScore > surferObject.high_score ? surferObject.high_score = roundedScore : null;
          } else {
            surferObject.high_score = roundedScore
          }
        }
      })
      HttpService.saveSurfer(surferObject).then(() => {
        return HttpService.getSurfers().then((response) => {
          commit('SET_SURFERS', response.data.surfers, { root: true })
        })
      })
      // update heat object
      return HttpService.updateHeatId({heat_number: payload.heat_number, heat: currentHeat}).then((response) => {
        commit('UPDATE_HEAT', response.data.heat)
      })
    },
    async clearWave({ commit, rootState }, payload ) {
      // payload will include heat_number, heatTeam, surferNumber, judgeID (if equal to 0, is master judge update), and newScore, and score
      let currentHeat = {} 
      let surfer = {}
      let surferObject = {}

      // find the heat to get the most recent updated scores
      await HttpService.getHeatId(payload).then((response) => {
        currentHeat = response.data.heat
        // Set the current team
        const team = currentHeat.teams[payload.heatTeam-1]
        
        // set the target surfer
        payload.surferNumber === "1" ? surfer = team.scores.surfer_1 : surfer = team.scores.surfer_2

        // find the surfer object in the surfers database so we can update their score averages
        const teamObject = rootState.teams.teams.find(teamInArray => teamInArray.team_id == team.team_id)
        payload.surferNumber === "1" ? surferObject = rootState.surfers.surfers.find(surfer => surfer.id == teamObject.surfer_1_id) : surferObject = rootState.surfers.surfers.find(surfer => surfer.id == teamObject.surfer_2_id)
        
        // replace the target score with the zero'd out score
        surfer.scores[payload.newScore.wave_id - 1] = payload.newScore
      
        // create wave average for this new wave
        const avgScore = (parseFloat(surfer.scores[payload.score.wave_id -1].scores[0]) + parseFloat(surfer.scores[payload.score.wave_id -1].scores[1]) + parseFloat(surfer.scores[payload.score.wave_id -1].scores[2]))/3
        const roundedScore = avgScore.toFixed(2)

        surfer.scores[ payload.score.wave_id -1 ].average = roundedScore

        // replace high score if applicable and recalculate team score
        
        // find the highest score in the scores array
        let newHighScore = 0;
        surfer.scores.forEach( (score) => {
          if ( score.average > newHighScore ) {
            newHighScore = score.average;
          }
        })
        surfer.high_score = newHighScore;

        const surfer_1_score = Math.round(team.scores.surfer_1.high_score * 100) / 100
        const surfer_2_score = Math.round(team.scores.surfer_2.high_score * 100) / 100

        team.scores.team_score = ((surfer_1_score) + (surfer_2_score)).toFixed(2);

        // update surfer stats

        // add score to list of scores
        if ( surferObject.all_scores ) {
          surferObject.all_scores.push(parseFloat(roundedScore))
        } else {
          surferObject.all_scores = []
          surferObject.all_scores.push(parseFloat(roundedScore))
        }

        // update average score
        let totalWaveScore = 0
        surferObject.all_scores.forEach(function(score) {
          totalWaveScore += score
        })
        surferObject.avg_score = totalWaveScore / surferObject.all_scores.length

        // update high score
        if (surferObject.high_score) {
          roundedScore > surferObject.high_score ? surferObject.high_score = roundedScore : null;
        } else {
          surferObject.high_score = roundedScore
        }

      })
      HttpService.saveSurfer(surferObject).then(() => {
        return HttpService.getSurfers().then((response) => {
          commit('SET_SURFERS', response.data.surfers, { root: true })
        })
      })
      // update heat object
      return HttpService.updateHeatId({heat_number: payload.heat_number, heat: currentHeat}).then((response) => {
        commit('UPDATE_HEAT', response.data.heat)
      })
    }
  }
}

export default new Vuex.Store({
  state: {
    initialized: false,
    loggedIn: false,
    role: '',
    startTime: [
      '8:00 AM', '8:15 AM','8:30 AM','8:45 AM','9:00 AM','9:15 AM','9:30 AM','9:45 AM','10:00 AM','10:15 AM','10:30 AM','10:45 AM','11:00 AM','11:15 AM','11:30 AM','11:45 AM','12:00 PM','12:00 PM','12:15 PM','12:15 PM','12:30 PM','12:30 PM','12:45 PM','12:45 PM','1:00 PM','1:00 PM','1:15 PM','1:15 PM','1:30 PM','1:30 PM','1:45 PM','1:45 PM','2:00 PM','2:00 PM','2:15 PM','2:15 PM','2:30 PM','2:30 PM','2:45 PM','2:45 PM','3:00 PM','3:00 PM','3:15 PM','3:15 PM','3:30 PM','3:45 PM','4:00 PM'
    ]
  },
  mutations: {
    INITIALIZE(state) {
      state.currentHeat ++
      state.initialized = true
    },
    LOGIN(state, user) {
        state.loggedIn = true
        if (user == 'Jerm') {
          state.role = 'Admin'
        } else {
          state.role = user
        }
    },
    LOGOUT(state) {
      state.loggedIn = false
      state.role = ''
    },
    UPDATE_TIMES(state,startTime) {
      state.startTime = startTime
    }
  },
  actions: {
    initalize({commit}) {
      commit.INITIALIZE()
    },
    login({commit}, password) {
      if (password === 'Admin' || password === 'Jerm' || password === 'Judge1' || password === 'Judge2' || password === 'Judge3' || password === 'Registration' ) {
        commit('LOGIN',password)
        localStorage.loggedIn = true
        localStorage.role = password
        return true
      } else {
        return false
      }
    },
    logout( {commit} ) {
      commit('LOGOUT')
      localStorage.clear()
    },
    checkCookies( { commit } ) {
      const loggedIn = localStorage.loggedIn
      const role = localStorage.role
      if ( loggedIn ) {
        commit('LOGIN', role)
      }
    },
    updateTimes({ commit }, startTimes ) {
      commit('UPDATE_TIMES', startTimes )
    }
  },
  modules: {
    teams: teamModule,
    heats: heatModule,
    surfers: surferModule
  }
});


