<template>
  <tr class="surfer-input" :class="{ 'on-team': onTeam }">
    <td>
      <input class="checked-in" type="checkbox" v-model="surfer.checked_in">
    </td>
    <td>
      <input type="text" v-model="surfer.first_name">
    </td>
    <td>
      <input type="text" v-model="surfer.last_name">
    </td>
    <td>
      <input class="age" type="number" v-model="surfer.age">
    </td>
    <td>
      <input type="text" v-model="surfer.hometown">
    </td>
    <td>
      <input type="text" v-model="surfer.stance">
    </td>
     <td>
      <input type="text" v-model="surfer.phone">
    </td>
    <td>
      <input type="text" v-model="surfer.image">
    </td>
    <td>
      <div class="button save" @click="saveSurfer()">Save</div>
    </td>
    <td>
      <div class="button delete" @click="deleteSurfer()">Delete</div>
    </td>
  </tr>
</template>

<script>
  import { mapState } from 'vuex'
  import SurferInput from '@/components/SurferInput.vue';
  export default {
    components: {
      SurferInput
    },
    props: {
      surferProp: Object
    },
    data() {
      return {
        surfer: {
          id:0,
          first_name:'',
          last_name:'',
          stance:'',
          hometown:'',
          age:0,
          image:'',
          phone: '',
          checked_in: false
        }
      }
    },
    mounted() {
      this.surfer.id = this.surferProp.id
      this.surfer.first_name = this.surferProp.first_name
      this.surfer.last_name = this.surferProp.last_name
      this.surfer.age = this.surferProp.age
      this.surfer.hometown = this.surferProp.hometown
      this.surfer.stance = this.surferProp.stance
      this.surfer.phone = this.surferProp.phone
      this.surfer.image = this.surferProp.image
      this.surfer.checked_in = this.surferProp.checked_in
    },
    computed: {
      onTeam() {
        let surferOnTeam = false
        const el = this;
        this.teams.forEach(function(team) {
          if (team.surfer_1_id == el.surfer.id || team.surfer_2_id == el.surfer.id ) {
            surferOnTeam = true
          }
        })
        return surferOnTeam
      },
      ...mapState({
        surfers: state => state.surfers.surfers,
        teams: state => state.teams.teams
      })
    },
    methods: {
      deleteSurfer() {
        this.$store.dispatch('deleteSurfer', this.surfer)
      },
      saveSurfer() {
        this.$store.dispatch('saveSurfer', this.surfer)
      }
    }
  }
</script>

<style lang="scss" scoped>
.button {
  cursor: pointer;
  padding: .5rem 0;
  width: 50px;
  text-align: center;
  font-size: .75rem;
  text-transform: uppercase;
  color: white;
}
.delete {
  background: red;
}
.save {
  background: green;
}
input {
  width: 125px;
  font-size: 14px;
}
.on-team {
  background: rgb(176, 228, 176);
}
.checked-in {
  width: 50px;
}
.age {
  width: 50px;
}
</style>