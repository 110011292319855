<template>
  <div class="team-card p-0">
    <div class="col-12 team-score-bar text-center">
      <p>Team Score: {{ currentHeat.teams[this.heatTeam - 1].scores.team_score }}</p>
    </div>
    <div class="row justify-content-center px-3">
      <div class="col-6 col-xl-5">
        <HeatScores v-if="team" :surfer_id="team.surfer_1_id" surferNumber=1 :heatTeam="heatTeam" :heatNumber="heatNumber" />
      </div>
      <div class="col-6 col-xl-5 offset-xl-1">
        <HeatScores v-if="team" :surfer_id="team.surfer_2_id" surferNumber=2 :heatTeam="heatTeam" :heatNumber="heatNumber" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HeatScores from '@/components/HeatScores.vue';
  export default {
    name: 'TeamCard',
    props: {
      heatTeam: {
        type: [Number, String]
      },
      heatNumber: {
        type: [ Number, String ]
      }
    },
    data() {
      return {
        score: 0
      }
    },
    components: {
      HeatScores
    },
    computed: {
      team() {
        // taking the heatTeam prop, which is 1 or 2, and using that to match up to the team_id 
        return this.teams.find(team => team.team_id == this.currentHeat.teams[this.heatTeam-1].team_id)
      },
      currentHeat() {
        return this.heats.find(heat => heat.heat_number == this.heatNumber)
      },
      ...mapState({
        teams: state => state.teams.teams,
        heats: state => state.heats.heats        
      })
    }
  }
</script>

<style lang="scss" scoped>

.team-card {
  background: #EFEFEF;  
  border: 5px solid #077d88;
  .team-score-bar {
    padding: 1rem 1rem .5rem;
    background: #077d88;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.25rem;
  }
  &.winning {
    border: 5px solid #3a9e91;
    .team-score-bar {
      background: #3a9e91;
    }
  }
}

</style>