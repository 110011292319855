<template>
  <div class="container-fluid text-center py-5 dark-bg">
    <div class="row mb-5">
      <div class="col-12">
        <img class="clam-jam-logo" src="@/assets/clam-2024.png" alt="2024 Clam Jam">
        <!-- <TextTest /> -->
      </div>
    </div>
    <!-- <div class="row justify-content-center">
      <div class="col-8 mb-4">
        <div class="iframe-container">
          <iframe class="responsive-iframe" src="https://www.youtube.com/embed/2bXMNEwcvd8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
       </div>
    </div> -->
    <template v-if="winningTeam === 0">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 v-if="currentHeat != 0" class="text-center text-white">In the Water</h1>
        </div>
        <template v-if="currentHeat <= 16">
          <template v-if="currentHeat == 0">
            <PreContest />
          </template>
          <template v-if="currentHeat != 0">
            <ThreeHeatView />
          </template>
        </template>
        <template v-if="currentHeat >=17 && currentHeat <=44">
          <FourHeatView />
        </template>
        <template v-if="currentHeat >=45">
          <TwoHeatView />
        </template>
        <template v-if="currentHeat == 48">
          <h1 class="text-white">Waiting on Final Scores...</h1>
        </template>
      </div>
    </template>
    <template v-if="winningTeam != 0">
      <Victory />
    </template>
</div>
</template>

<script>
import { mapState } from 'vuex'
import ThreeHeatView from '@/components/ThreeHeatView.vue';
import TwoHeatView from '@/components/TwoHeatView.vue';
import FourHeatView from '@/components/FourHeatView.vue';
import Victory from '@/components/Victory.vue';
import PreContest from '@/components/PreContest.vue'
import TextTest from "@/components/TextTest.vue"

  export default {
    name: 'Home',
    components: {
      TwoHeatView,
      ThreeHeatView,
      FourHeatView,
      Victory,
      PreContest,
      TextTest
    },
    mounted() {
      if (this.teams.length<1) {
        this.$store.dispatch('getTeams')
      }
      if(this.heats.length < 1 ) {
        this.$store.dispatch('getHeats')
      }
      if(this.surfers.length < 1 ) {
        this.$store.dispatch('getSurfers')
      }
      this.$store.dispatch('getWinningTeam')
      this.$store.dispatch('getCurrentHeat')
      // this will reload scores every 30 seconds
      if (!this.admin) {
        const el = this
        setInterval(function() {
          el.$store.dispatch('getHeats')
          el.$store.dispatch('getCurrentHeat')
          el.$store.dispatch('getTeams')
        }, 30000)
      }
    },
    computed: {
      showTeam() {
        return true
      },
      ...mapState({
        surfers: state => state.surfers.surfers,
        teams: state => state.teams.teams,
        heats: state => state.heats.heats,
        currentHeat: state => state.heats.currentHeat,
        liveHeat: state => state.heats.liveHeat,
        admin: state => state.admin,
        winningTeam: state => state.heats.winningTeam
    })
  }
}
</script>

<style lang="scss" scoped>
  .clam-jam-logo {
    height: auto;
    width: 300px;
  }

  .iframe-container {
    position: relative;
    overflow: hidden;
   width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  .responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>