<template>
  <div>
    <div class="score-display" @click="openScoreModal()">{{ score.scores[judgeID - 1] }}</div>
    <div class="score-modal" v-show="modalOpen">
        <div class="score-modal__grid">
            <div class="score-modal__header">
                <h2 class="text-center">Enter Score</h2>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('0.'+(loopScore-1)), $emit('submit')">0.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('1.'+(loopScore-1)), $emit('submit')">1.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('2.'+(loopScore-1)), $emit('submit')">2.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('3.'+(loopScore-1)), $emit('submit')">3.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('4.'+(loopScore-1)), $emit('submit')">4.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('5.'+(loopScore-1)), $emit('submit')">5.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('6.'+(loopScore-1)), $emit('submit')">6.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('7.'+(loopScore-1)), $emit('submit')">7.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('8.'+(loopScore-1)), $emit('submit')">8.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score" v-for="loopScore in 10" :key="loopScore" @click="addScore('9.'+(loopScore-1)), $emit('submit')">9.{{ loopScore-1 }}</div>
            </div>
            <div class="score-modal__row">
                <div class="score-modal__score perfect-ten" @click="addScore(10), $emit('submit')">10</div>
            </div>
            <div class="score-modal__footer">
                <div class="score-modal__cancel" @click="closeScoreModal()">
                    Cancel
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "ScoreCard",
    props: {
        surferNumber: {
            type: [Number, String]
        },
        heatTeam: {
            type: [Number, String]
        },
        heatNumber: {
            type: [ Number, String ]
        },
        judgeID: {
            type: [ Number, String ]
        },
        score: {
            type: Object
        }
    },
    data() {
        return {
            modalOpen: false
        }
    },
    methods: {
        openScoreModal() {
            this.modalOpen = true;
        },
        closeScoreModal() {
            this.modalOpen = false;
        },
        addScore(buttonScore) {
            this.$store.dispatch('updateScore', { heat_number: this.heatNumber, heatTeam: this.heatTeam, surferNumber: this.surferNumber, judgeID: this.judgeID, newScore: buttonScore, score: this.score })
            this.closeScoreModal();
        }
    }
}
</script>

<style>
    .score-display {
        width: 60px;
        height: 30px;
        text-align: center;
        background: white;
        border: 1px solid #888888;
    }
    .score-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.5);
    }
    .score-modal__grid {
        position: absolute;
        width: 540px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .score-modal__grid {
        background: rgba(131, 230, 248);
    }
    .score-modal__row {
        display: flex;
        margin: 4px;
    }
    .score-modal__score {
        background: rgba(255,255,255);
        width: 58px;
        margin: 0 2px;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .score-modal__score.perfect-ten {
        width: 100%;
    }

    .score-modal__footer {
        display: flex;
        justify-content: center;
        padding: 5px 0;
    }

    .score-modal__cancel {
        width: 150px;
        padding: 10px 0;
        background-color: #ba3d3d;
        text-align: center;
        color: white;
    }
</style>