<template>
  <div class="container">
    <div class="pyro">
      <div class="before"></div>
      <div class="after"></div>
    </div>
    <div class="row justify-content-center">
      <h1 class="text-center text-light">Congratulations to the 2024 Clam Jam Winners:</h1>
      <p class="text-center text-light" v-if="winningSurfer1 != undefined && winningSurfer2 != undefined">{{ winningSurfer1.first_name }} {{ winningSurfer1.last_name }} and {{ winningSurfer2.first_name }} {{ winningSurfer2.last_name }} </p>
    </div>
    <div class="row justify-content-center mt-5"> 
      <div class="col-12">
        <b-button :to="{name: 'Bracket'}">View the Final Bracket</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
  export default {
    name: 'Victory',
    methods: {

    },
    computed: {
      winningSurfer1() {
        const team = this.teams.find(team => team.team_id === this.winningTeam)
        return this.surfers.find(surfer => surfer.id === team.surfer_1_id)
      },
      winningSurfer2() {
        const team = this.teams.find(team => team.team_id === this.winningTeam)
        return this.surfers.find(surfer => surfer.id === team.surfer_2_id)
      },
      ...mapState({
        teams: state => state.teams.teams,
        winningTeam: state => state.heats.winningTeam,
        surfers: state => state.surfers.surfers
      })
    }
  }
</script>

<style lang="scss" scoped>
p {
  font-size: 2.5rem;
}

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
               random($width)-$width / 2 + px
               random($height)-$height / 1.2 + px
               hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff
}
@mixin keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-delay ($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
}

@mixin animation-duration ($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
}

@mixin animation ($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
}

@mixin transform ($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
}

body {
  margin:0;
  padding:0;
  background: #000;
  overflow: hidden;
}

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
}
    
.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}
        
@include keyframes(bang) {
  to {
    box-shadow:$box-shadow;
  }
}
    
@include keyframes(gravity)  {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}
    
@include keyframes(position) {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {  
    margin-top: 20%;
    margin-left: 70%
  }
  60%, 79.9% {  
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {  
    margin-top: 30%;
    margin-left: 80%;
  }
}

</style>