<template>
  <div v-if="heat" class="heat-in-water container my-5 py-3">
    <div class="d-flex justify-content-between">
      <h2>Heat {{ prettyHeatNumber }} - {{ heatRound }}</h2>
      <div class="d-flex align-items-center">
        <div @click.prevent="doneJudging(judgeReady)" class="btn judge-button" :class="judgeReady?'ready':'not-ready'">{{ judgeReady?'Undo Scores':'Done Judging' }}</div>
      </div>
    </div>
    <div class="mt-2">
      <TeamCard v-for="(team,index) in heat.teams" :heatTeam = "index+1" :heatNumber = "heatNumber" :key = "index" :master = "master" :judgeID = "judgeID" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TeamCard from '@/components/master/TeamCard'
export default {
  components: {
    TeamCard
  },
  props: {
    heatNumber: {
      type: [String, Number]
    },
    master: {
      type: [Boolean]
    },
    judgeID: {
      type: [Number, String]
    }
  },
  computed: {
    heat() {
      return this.heats.find( heat => heat.heat_number == this.heatNumber )
    },
    judgeReady() {
      return this.heat.judges_ready[this.judgeID - 1]
    },
    heatRound() {
      if (this.heat.round_of == 48) {
        return "Round 1"
      } else if  ( this.heat.round_of == 32 ) {
        return "Round 2"
      } else if ( this.heat.round_of == 16 ) {
        return "Round 3"
      } else if ( this.heat.round_of == 8 ) {
        return "Quarterfinals" 
      } else if ( this.heat.round_of == 4 ) {
        return "Semifinals" 
      } else if ( this.heat.round_of == 2 ) {
        return "Finals" 
      } 
    },
     prettyHeatNumber() {
      if (this.heat.round_of == 48) {
        return this.heatNumber
      } else if  ( this.heat.round_of == 32 ) {
        return this.heatNumber - 16
      } else if ( this.heat.round_of == 16 ) {
        return this.heatNumber - 32
      } else if ( this.heat.round_of == 8 ) {
        return this.heatNumber - 40
      } else if ( this.heat.round_of == 4 ) {
        return this.heatNumber - 44
      } else if ( this.heat.round_of == 2 ) {
        return this.heatNumber - 46
      } 
    },
    activeHeat() {
      if ( this.currentHeat == this.heat.heat_number ) {
        return true
      } else if ( this.currentHeat > 16 && this.currentHeat < 45 ) {
        if ( this.currentHeat + 1 == this.heat.heat_number ) {
          return true
        }
      } else {
         return false
      }
    },
    winningTeam() {
      if ( this.heat.teams.length < 2 ) {
        // checks to see which team is winning the heat or if it is tied
        const teamOneScore = this.heat.teams[0].scores.team_score;
        const teamTwoScore = this.heat.teams[1].scores.team_score;

        if ( teamOneScore > teamTwoScore) {
          return 'team_1'
        } else if ( teamTwoScore > teamOneScore ) {
          return 'team_2'
        } else {
          return 'tied'
        }
      } else {
        // checks to see which team is winning the heat or if it is tied
        const teamOneScore = this.heat.teams[0].scores.team_score;
        const teamTwoScore = this.heat.teams[1].scores.team_score;
        const teamThreeScore = this.heat.teams[2].scores.team_score;
  
        if ( teamOneScore > teamThreeScore && teamTwoScore > teamThreeScore ) {
          return '1_2'
        } else if ( teamTwoScore > teamOneScore && teamThreeScore > teamOneScore ) {
          return '2_3'
        } else if ( teamOneScore > teamTwoScore && teamThreeScore > teamTwoScore ) {
          return '1_3'
        }else {
          return 'tied'
        }
      }
    },
    ...mapState({
      teams: state => state.teams.teams,
      heats: state => state.heats.heats,
      currentHeat: state => state.heats.currentHeat,
      liveHeat: state => state.heats.liveHeat,
      admin: state => state.admin
    })
  },
  methods: {
    doneJudging(judgeStatus) {
      // conditionals added to make sure this judge has ended the previous heat before moving on, preventing out of order heat skipping
      // doesn't apply for first heat
      if (this.heatNumber <= 1 ) {
        judgeStatus = !judgeStatus
        this.$store.dispatch('doneJudging', { heat_number: this.heatNumber, judgeID: this.judgeID, judge_ready: judgeStatus })
      } else {
        if ( this.heats[this.heatNumber - 2 ].judges_ready[this.judgeID - 1]) {
          judgeStatus = !judgeStatus
          this.$store.dispatch('doneJudging', { heat_number: this.heatNumber, judgeID: this.judgeID, judge_ready: judgeStatus })
        } else {
          alert('Please finish judging the previous heat.')
        }
      }
    }
  }
}
</script>

<style>
.heat-in-water {
  background: white;
  border: 1px solid gray;
}

.judge-button.not-ready {
  background: orange;
}

.judge-button.ready {
  background: green;
  color:white;
}
</style>