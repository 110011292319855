<template>
  <div class="container">
    <h1 class="text-center my-5 text-white">Manage Teams</h1>
    <TeamSelect v-for="team in sortedTeams" :team="team" :key="team.team_id" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import TeamSelect from '@/components/TeamSelect.vue'
  export default {
    components: {
      TeamSelect
    },
    mounted() {
      this.$store.dispatch('getSurfers')
      this.$store.dispatch('getTeams')
    },
    computed: {
      sortedTeams() {
        let sortedTeamsList = this.teams.sort((a,b) => (a.team_id > b.team_id) ? 1 : -1 )
        return sortedTeamsList;
      },
      ...mapState({
        surfers: state => state.surfers.surfers,
        teams: state => state.teams.teams
      })
    }
  }
</script>

<style lang="scss" scoped>
</style>