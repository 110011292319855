import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Bracket from "../views/Bracket.vue";
import Admin from "../views/Admin.vue";
import Login from '../views/Login.vue';
import Surfers from '../views/Surfers.vue'
import Teams from '../views/Teams.vue'
import MasterJudge from '../views/MasterJudge.vue'
import Judge1View from '../views/Judge1View.vue'
import Judge2View from '../views/Judge2View.vue'
import Judge3View from '../views/Judge3View.vue'
import VueGtag from "vue-gtag"


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/bracket",
    name: "Bracket",
    component: Bracket
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/surfers',
    name: 'Surfers',
    component: Surfers
  },
  {
    path: '/teams',
    name: 'Teams',
    component: Teams
  },
  {
    path: '/master/scores',
    name: 'Master',
    component: MasterJudge
  },
  {
    path: '/master/judge-1',
    name: 'Judge 1',
    component: Judge1View
  },
  {
    path: '/master/judge-2',
    name: 'Judge 2',
    component: Judge2View
  },
  {
    path: '/master/judge-3',
    name: 'Judge 3',
    component: Judge3View
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

Vue.use(VueGtag, {
  config: { id: "G-MEP1YQB78N" }
}, router);

export default router;
