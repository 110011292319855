<template>
  <div v-if="teams.length > 0">
    <h1 class="text-center mt-5 text-white">Master Judge View</h1>
    <div class="d-flex justify-content-center align-items-center my-3">
      <!-- <div class="increment-button" @click="stepBackHeat">-</div> -->
      <!-- Removed the step forward and back button bc they weren't necessary, and were messing with the bracket and contest heat sync -->
      <h2 class="text-white mx-0">Current Heat: {{ currentHeat }}</h2>
      <!-- <div class="increment-button" @click="stepForwardHeat">+</div> -->
    </div>
    <MasterJudgeSheet v-for="heat in heats" :heatNumber="heat.heat_number" :key="heat.heat_number" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MasterJudgeSheet from '@/components/MasterJudge/MasterJudgeSheet'
export default {
  components: {
    MasterJudgeSheet
  },
  name: 'MasterJudgeView',
  beforeMount(){
    if(!this.$store.state.loggedIn  && this.$store.state.role != 'Admin') {
      this.$router.push({name: 'Home'})
    }
    if (this.teams.length<1) {
      this.$store.dispatch('getTeams')
    }
    if(this.heats.length < 1 ) {
      this.$store.dispatch('getHeats')
    }
    if(this.surfers.length < 1 ) {
      this.$store.dispatch('getSurfers')
    }
    this.$store.dispatch('getCurrentHeat')
  },
  mounted() {
    this.$store.dispatch('getHeats')
  },
  computed: {
    ...mapState({
      surfers: state => state.surfers.surfers,
      teams: state => state.teams.teams,
      heats: state => state.heats.heats,
      currentHeat: state => state.heats.currentHeat,
      admin: state => state.admin,
      winningTeam: state => state.heats.winningTeam
    })
  },
  methods: {
    updateHeat() {
     
    },
    stepForwardHeat() {
      this.$store.dispatch('getNextHeat')
    },
    stepBackHeat() {
      this.$store.dispatch('getPreviousHeat')
    }
  }
}
</script>

<style lang="scss">
.increment-button {
  background: orange;
  padding: .5rem 2rem;
  margin: .5rem;
}
 
</style>