<template>
  <div class="create-surfer d-flex my-5 align-items-center">
    <div>
      <label>ID</label>
      {{ surfers.length + 1 }}
    </div>
    <div>
      <label>Checked In</label>
      <input class="checked-in" type="checkbox" v-model="surfer.checked_in">
    </div>
    <div>
      <label>First Name</label>
      <input type="text" v-model="surfer.first_name">
    </div>
    <div>
      <label>Last Name</label>
      <input type="text" v-model="surfer.last_name">
    </div>
    <div>
      <label>Age</label>
      <input class="age" type="number" v-model="surfer.age">
    </div>
    <div>
      <label>Hometown</label>
      <input type="text" v-model="surfer.hometown">
    </div>
    <div>
      <label>Stance</label>
      <input type="text" v-model="surfer.stance">
    </div>
    <div>
      <label>Phone</label>
      <input type="text" v-model="surfer.phone">
    </div>
    <div>
      <label>Image</label>
      <input type="text" v-model="surfer.image">
    </div>
    <div>
      <div class="btn btn-success" @click="createSurfer()">Create</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      surfer: {
          id:0,
          first_name:'',
          last_name:'',
          stance:'',
          phone: '',
          hometown:'',
          age:0,
          image:'',
          checked_in: false
        }
    }
  },
  methods: {
    createSurfer() {
      const el = this;
      this.surfers.forEach(function(surfer) {
        if( surfer.id >= el.surfer.id ) {
          el.surfer.id = surfer.id + 1
        }
      })
      this.$store.dispatch('createSurfer', this.surfer)
      this.clear()
    },
    clear() {
      this.surfer.id = 0;
      this.surfer.first_name = ''
      this.surfer.last_name = ''
      this.surfer.hometown = ''
      this.surfer.stance = ''
      this.surfer.age = 0
      this.surfer.image = ''
      this.surfer.phone = ''
    }
  },
  computed: mapState({
    surfers: state => state.surfers.surfers
  })
}
</script>

<style lang="scss" scoped>
input {
  width: 100px;
  font-size: 14px;
}
label {
  font-size: 14px;
  white-space:nowrap;
}
.create-surfer {
  background: white;
  padding: 1rem;
  overflow-x: scroll;
}
.checked-in {
  width: 50px;
}
.age { 
  width: 40px;
}


</style>