<template>
  <div class="container py-5 text-center">
    <p class="text-light">Warning: Clicking this button will reset the contest!!</p>
    <b-button variant="danger" @click="initContest">Init Contest</b-button>
    <b-button variant="danger" @click="initTeams">Init Teams</b-button>
  </div>
</template>

<script>
  export default {
    beforeMount(){
      if(!this.$store.state.loggedIn) {
        this.$router.push({name: 'Home'})
      }
    },
    methods: {
      initContest() {
        this.$store.dispatch('initContest')
      },
      initTeams() {
        this.$store.dispatch('initTeams')
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>