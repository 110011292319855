<template>
  <div class="top-nav">
    <b-navbar type="dark" variant="dark" class="d-flex justify-content-evenly">
      <b-navbar-nav>
        <b-nav-item router-link :to="{name: 'Home'}">Live Scores</b-nav-item>
        <b-nav-item router-link :to="{name: 'Bracket'}">Bracket</b-nav-item>
        <b-nav-item v-if="$store.state.role == 'Admin' || $store.state.role == 'Registration' " router-link :to="{name: 'Surfers'}">Surfers</b-nav-item>
        <b-nav-item v-if="$store.state.role == 'Admin' || $store.state.role == 'Registration' " router-link :to="{name: 'Teams'}">Teams</b-nav-item>
        <b-nav-item v-if="$store.state.role == 'Admin' " router-link :to="{name: 'Master'}">Scores Admin</b-nav-item>
        <b-nav-item v-if="$store.state.role == 'Judge1' " router-link :to="{name: 'Judge 1'}">Scoring</b-nav-item>
        <b-nav-item v-if="$store.state.role == 'Judge2' " router-link :to="{name: 'Judge 2'}">Scoring</b-nav-item>
        <b-nav-item v-if="$store.state.role == 'Judge3' " router-link :to="{name: 'Judge 3'}">Scoring</b-nav-item>
        <b-nav-item v-if="$store.state.loggedIn" @click.prevent="logout()">Logout</b-nav-item>
        <!-- <p>{{ $store.state.loggedIn }} - {{ $store.state.role }}</p> -->
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
  export default {
    methods: {
      logout() {
        this.$store.dispatch('logout')
        this.$router.push({name: 'Home'})
      }
    },
    beforeMount() {
      this.$store.dispatch('checkCookies')
    }
  }
</script>

<style lang="scss" scoped>
  .bg-dark {
    background-color: #077d88 !important;
    border-bottom: 1px solid #077d88!important;
  }
  .nav-item {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0 1rem;
  }
</style>