<template>
  <div class="container-fluid" v-if="heat">
    <div class="row">
      <div class="col-12 text-center mb-3 text-white">
        <h2 class="text-white">Heat {{ prettyLiveHeat }}</h2>
        <p class="text-white">Round of {{ heat.round_of }}</p>
      </div>
    </div>
    <div class="row justify-content-center px-3">
      <div class="col-12 col-lg-4 mb-3">
        <TeamCard heatTeam=1 :heatNumber="currentHeat" :class="{ winning : winningTeam == 'team_1'}"/>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <TeamCard heatTeam=2 :heatNumber="currentHeat" :class="{ winning : winningTeam == 'team_2'}"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex' 
import TeamCard from '@/components/TeamCard.vue';
  export default {
    name: 'TwoHeatView',
    components: {
      TeamCard
    },
    computed: {
      heat() {
        return this.heats.find(heat => heat.heat_number == this.currentHeat)
      },
       prettyLiveHeat() {
        if (this.heat.round_of == 48) {
          return this.currentHeat
        } else if  ( this.heat.round_of == 32 ) {
          return this.currentHeat - 16
        } else if ( this.heat.round_of == 16 ) {
          return this.currentHeat - 32
        } else if ( this.heat.round_of == 8 ) {
          return this.currentHeat - 40
        } else if ( this.heat.round_of == 4 ) {
          return this.currentHeat - 44
        } else if ( this.heat.round_of == 2 ) {
          return this.currentHeat - 46
        }
      },
      winningTeam() {
        // checks to see which team is winning the heat or if it is tied
        const teamOneScore = this.heat.teams[0].scores.team_score;
        const teamTwoScore = this.heat.teams[1].scores.team_score;

        if ( teamOneScore > teamTwoScore) {
          return 'team_1'
        } else if ( teamTwoScore > teamOneScore ) {
          return 'team_2'
        }else {
          return 'tied'
        }
      },
      ...mapState({
        teams: state => state.teams.teams,
        heats: state => state.heats.heats,
        currentHeat: state => state.heats.currentHeat,
        admin: state => state.admin
    })
    }
  }
</script>

<style lang="scss" scoped>

.end-heat {
  display: block;
  background: black;
  padding: 1rem 3rem;
  margin-top: 2rem;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: white;
}
</style>