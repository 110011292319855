<template>
  <div class="container">
    <h1 class="text-center my-5 text-white">Manage Surfers</h1>
    <CreateSurfer />
    <table>
      <tr>
        <th>Checked In</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Age</th>
        <th>Hometown</th>
        <th>Stance</th>
        <th>Phone</th>
        <th>Profile Image</th>
        <th colspan="2">Update</th>
      </tr>
      <SurferInput v-for="surfer in sortedSurfers" :key="surfer.id" :surferProp="surfer" />
    </table>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import SurferInput from '@/components/SurferInput.vue';
  import CreateSurfer from '@/components/CreateSurfer.vue';
  export default {
    components: {
      SurferInput,
      CreateSurfer
    },
    mounted() {
      this.$store.dispatch('getSurfers')
      this.$store.dispatch('getTeams')
    },
    methods: {
      createSurfer() {
        const surfer = {
          id: 1,
          first_name: 'Jane',
          last_name: 'Smith',
          stance: 'Goofy',
          age: '30'
        }
        this.$store.dispatch('createSurfer', surfer);
      }
    },
    computed: {
      sortedSurfers() {
        if ( this.surfers.length > 0 ) {
          return this.surfers.sort((a,b) => {
            let nameA = a.first_name.toLowerCase()
            let nameB = b.first_name.toLowerCase()
            if( nameA < nameB ) {
              return -1
            } else if ( nameA > nameB ) {
              return 1
            } else {
              return 0
            }
          })
        }
      },
      ...mapState({
        surfers: state => state.surfers.surfers
      })
    }
  }
</script>

<style lang="scss" scoped>
tr {
  background-color: white;
  border: 1px solid gray;
}
td, th {
  padding: .5rem .15rem;
  font-size: 14px;
}
.container {
  overflow-x: scroll;
}

</style>