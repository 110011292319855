<template>
  <div>
    <div class="button" @click="sendText()">Click Me</div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  methods: {
    sendText() {
      const simpleTextClient = axios.create({
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Authorization',
          'Authorization': 'Bearer 9d96fe869f11a61d1f412ba30284c3ba'
        },
        baseURL: 'https://api-app2.simpletexting.com/v2'
      })
      
      const body = { "contactPhone": "8087291320", "accountPhone": "2089746815", "mode": "MMS_PREFERRED", "text": "Hello! How are you?", "subject": "Some message from SimpleTexting", "fallbackText": "[url=%%fallback_link%%]" }
      return simpleTextClient.post('/api/messages', body)
    }
  }
}
</script>

<style>
.button {
  display: inline-block;
  background: orange;
  padding: 2rem;
  text-align: center;
}
</style>