<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex justify-content-start align-items-start">
      <div class="jersey-color">
        <JerseyColor :color="jerseyColor"/>
      </div>
      <div class="surfer-name" v-if="surfer != undefined">
        <h3 v-if="surfer.first_name">{{ surfer.first_name }} {{ surfer.last_name }}</h3>
        <div class="score high-score">
          <p class="caption mb-0">Best Score:</p>
          <p class="score-text mb-0">{{ scores.high_score }}</p>
        </div>
      </div>
      <Score v-for="(score, index) in scores.scores" :score="score" :surferNumber="surferNumber" :heatTeam="heatTeam" :heatNumber="heatNumber" :waveNumber="index + 1" :key="index" />
    </div>
  </div>
</template>

<script>
import JerseyColor from '@/components/JerseyColor.vue';
import Score from '@/components/MasterJudge/Score.vue'
import { mapState } from 'vuex'
  export default {
    name: 'SurferScores',
    props: {
      surfer_id: {
        type: [Number, String]
      },
      surferNumber: {
        type: [Number, String]
      },
      heatTeam: {
        type: [Number, String]
      },
      heatNumber: {
        type: [ Number, String ]
      }
    },
    data() {
      return {
        score1: 0,
        score2: 0,
        score3: 0,
        scoreInputVisible: false
      }
    },
    components: {
      JerseyColor,
      Score
    },
    computed: {
      team() {
        return this.teams.find(team => team.team_id == this.currentHeat.teams[this.heatTeam-1].team_id)
      },
      surfer() {
        let newSurfer = this.surfers.find(surfer => surfer.id == this.surfer_id )
        return newSurfer;
      },
      currentHeat() {
        return this.heats.find(heat => heat.heat_number == this.heatNumber)
      },
      jerseyColor() {
        if(this.surferNumber == 1 ) {
          return this.currentHeat.teams[this.heatTeam-1].scores.surfer_1.jersey
        } else {
          return this.currentHeat.teams[this.heatTeam-1].scores.surfer_2.jersey
        }
      },
      scores() {
        // each heat has two surfers to a team, so this uses the surfer number prop to determine which one to show scores for
        if(this.surferNumber == 1 ) {
          return this.currentHeat.teams[this.heatTeam-1].scores.surfer_1
        } else {
          return this.currentHeat.teams[this.heatTeam-1].scores.surfer_2
        }
         
      },
      ...mapState({
        surfers: state => state.surfers.surfers,
        teams: state => state.teams.teams,
        heats: state => state.heats.heats,
        admin: state => state.admin
      })
    }
  }
</script>

<style lang="scss" scoped>
.jersey-color {
  width: 100px;
}
.surfer-name {
  width: 150px;
  h3 {
    font-size: 1rem;
  }
}
.score {
  height: 50px;
  width: 50px;
  margin-right: 1rem;
  background: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .caption {
    text-transform: uppercase;
    font-size: .7rem;
  }
}

.high-score {
  width: 100px;
  .score-text {
    font-weight: bold;
  }
}
</style>