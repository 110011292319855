<template>
  <div id="app" class="dark-bg">
    <MainNav />
    <router-view />
  </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue';
export default {
  components: {
    MainNav
  }
}
</script>


<style lang="scss">
.dark-bg {
  background-color: #3d4e54;
}
#app { 
  min-height: 100vh;
}

.nowrap-tab {
  white-space: nowrap !important;
}
</style>
