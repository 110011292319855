<template>
  <div class="score-container mb-3 mx-1" :class="scoreStatus">
    <div class="score" :class="scoreStatus"> 
      <p class="caption mb-0"><strong>Wave {{ waveNumber }}:</strong>  {{ score.average }}</p>
      <p class="clear-wave mb-0" @click="showPrompt()">x</p>
    </div>
    <div class="score-update">
      <form ref="newScores">
        <div  class="d-flex">
          <ScoreCard :score="score" :surferNumber="surferNumber" :heatTeam="heatTeam" :heatNumber="heatNumber" judgeID = "1"/>
          <ScoreCard :score="score" :surferNumber="surferNumber" :heatTeam="heatTeam" :heatNumber="heatNumber" judgeID = "2"/>
          <ScoreCard :score="score" :surferNumber="surferNumber" :heatTeam="heatTeam" :heatNumber="heatNumber" judgeID = "3"/>

        </div>
        <!-- <input class="btn btn-success" type="submit" @click.prevent="updateScores()"> -->
      </form>
    </div>
    <div v-if="showClearPrompt" class="clear-prompt">
      <div class="clear-prompt-outer"></div>
      <div class="clear-prompt-inner">
          <h2>Are you sure you want to clear the score?</h2>
          <div class="clear-buttons">
            <div class="btn btn-success" @click="clearWave()">Yes</div>
            <div class="btn btn-warning" @click="showClearPrompt = false">No</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import ScoreCard from '@/components/master/ScoreCard.vue'
export default {
  name: "Score",
  components: {
    ScoreCard
  },
  props: {
    score: {
      type: [Object]
    },
    surferNumber: {
        type: [Number, String]
      },
      heatTeam: {
        type: [Number, String]
      },
      heatNumber: {
        type: [ Number, String ]
      },
      waveNumber: {
        type: [ Number, String ]
      }
  },
  data() {
    return {
      showClearPrompt: false
    }
  },
  computed: {
    scoreStatus() {
      if ( this.score.scores[0] != 0 && this.score.scores[1] != 0 && this.score.scores[2] != 0) {
        return 'valid'
      } else if ( this.score.scores[0] == 0 || this.score.scores[1] == 0 || this.score.scores[2] == 0) {
        if ( this.score.scores[0] == 0 && this.score.scores[1] == 0 && this.score.scores[2] == 0) {
          return 'dormant'
        } else {
          return 'error'
        }
      } 
    }
  },
  methods: {
    updateScores() {      
      // heat -> team -> scores -> surfer -> scores -> update score, recalculate average, recalculate surfer high score
      let newScore = {
        scores: [],
        wave_id: Number,
        average: Number
      }
      newScore.scores.push(this.$refs.newScores.elements[0].value)
      newScore.scores.push(this.$refs.newScores.elements[1].value)
      newScore.scores.push(this.$refs.newScores.elements[2].value)
      newScore.wave_id = this.waveNumber
      this.$store.dispatch('updateScore', { heat_number: this.heatNumber, heatTeam: this.heatTeam, surferNumber: this.surferNumber, judgeID: 0, newScore: newScore, score: newScore } )
    },
    showPrompt() {
      this.showClearPrompt = true;
    },
    clearWave() {
      this.showClearPrompt = false;
      let newScore = {
        scores: [0,0,0],
        wave_id: this.waveNumber,
        average: 0
      }
      this.$store.dispatch('clearWave', { heat_number: this.heatNumber, heatTeam: this.heatTeam, surferNumber: this.surferNumber, judgeID: 0, newScore: newScore, score: newScore })
    }
  }
}
</script>

<style lang="scss" scoped>
.score {
  padding:.25rem;
  width: 100%;
  margin-right: .5rem;
  background: rgb(152, 152, 152);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .caption {
    text-transform: uppercase;
    font-size: .7rem;
  }
  .clear-wave {
    background: red;
    color: white;
    padding: 0 6px 3px;
    line-height: 1em;
  }
}
input {
  background: white;
  text-align: center;
  border: none;
}
.btn {
  background-color: #0d6efd;
  font-size: 10px;
  letter-spacing: .05rem;
  width: 100%;
  height: 30px;
  text-transform: uppercase;
}
.judge-score-input {
  width: 33px;
  font-size: 12px;
  height: 30px;
}
.valid {
  background-color: green;
  .btn {
    background-color: rgb(43, 164, 43);
  }
}
.error {
  background-color: rgb(234, 110, 110);
  .btn {
    background-color: red;
  }
}
.dormant {
  background-color: rgb(159, 159, 159);
  .btn {
    background-color: gray;
  }
}

.clear-prompt {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  .clear-prompt-outer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.7);
  }
  .clear-prompt-inner {
    background: white;
    z-index: 101;
    padding: 20px;
    .clear-buttons {
      display: flex;
      justify-content: space-between;
    }
    .btn.btn-warning {
      background: red;
      color:white;
    }
  }
}
</style>