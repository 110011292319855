import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${window.location.protocol}//${window.location.hostname}:3000/api`
})

export default {
  async initContest() {
    // Clear contest, add new
    await apiClient.post('/contest/create')
    // Clear heats, add new
    return apiClient.post('/heats/init')
  },
  endContest(body) {
    return apiClient.post('/contest/end', body)
  },
  getHeats() {
    return apiClient.get('/heats')
  },
  initTeams(body) {
    return apiClient.post('/teams/init', body)
  },
  getTeams() {
    return apiClient.get('/teams')
  },
  updateTeam(body) {
    return apiClient.post('/teams/update', body)
  },
  getCurrentHeat() {
    return apiClient.get('/contest')
  },
  getLiveHeat() {
    return apiClient.get('/contest')
  },
  nextHeat(body) {
    return apiClient.post('/contest/next-heat', body)
  },
  advanceLiveHeat(body) {
    console.log(body)
    return apiClient.post('/contest/next-live-heat', body)
  },
  getHeatId(query) {
    // query includes heat_number, heatTeam, surferNumber, and score 
    return apiClient.get('/heats/get', {params: query})
  },
  updateHeatId(body) {
    // body includes heat_number and heat
    return apiClient.post('/heats/update', body )
  },
  createSurfer(body) {
    return apiClient.post('/surfers/create', body)
  },
  saveSurfer(body) {
    return apiClient.post('/surfers/save', body)
  },
  getSurfers() {
    return apiClient.get('/surfers')
  },
  deleteSurfer(body) {
    return apiClient.post('/surfers/deleteOne', body)
  }
}

