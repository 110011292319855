<template>
  <div>
    <img @click="showModal = true" class="edit-icon" src="@/assets/edit.svg">
    <div class="team-change-modal text-center d-flex flex-column justify-content-center align-items-center" v-if="showModal">
      <h2 class="text-center mb-4">Change Team</h2>
      <select v-model="new_team_id">
        <option value="0">Empty Team</option>
        <option v-for="team in teams" :key="team.team_id" :value="team.team_id">{{ team.team_id }}: <Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /> and <Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /> - {{ checkedIn(team.team_id) }}</option>
      </select>
      <div class="d-flex justify-content-center mt-4">
        <div class="btn btn-success mx-1" @click="changeHeatTeam()">Update Team</div>
        <div class="btn btn-danger mx-1" @click="showModal = false">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Surfer from '@/components/Surfer.vue'
export default {
  name: 'TeamChange',
  components: {
    Surfer
  },
  props: {
    team_id: [Number, String],
    team_number: [Number, String],
    heat_number: [Number, String],
    surfers: Array,
    teams: Array
  },
  data() {
    return {
      showModal: false,
      new_team_id: '0'
    }
  },
  methods: {
    checkedIn(id) {
      const team = this.teams.find(team => team.team_id === id)
      const surferOne = this.surfers.find(surfer => surfer.id === team.surfer_1_id)
      const surferTwo = this.surfers.find(surfer => surfer.id === team.surfer_2_id)
      if (surferOne != undefined && surferTwo != undefined ) {
        if ( surferOne.checked_in == true && surferTwo.checked_in == true ) {
          return 'Checked In'
        } else {
          return 'Not Checked In'
        }
      }
    },
    changeHeatTeam() {
      let newHeat = this.heats.find(heat => heat.heat_number == this.heat_number )
      newHeat.teams[this.team_number].team_id = this.new_team_id
      this.$store.dispatch('updateHeatId', { heat_number: this.heat_number, heat: newHeat})
    }
  },
  computed: mapState({
    heats: state => state.heats.heats
  })
}
</script>

<style lang="scss" scoped>
select {
  height: 30px;
}
.edit-icon {
  height: 20px;
  width: auto;
}

.team-change-modal {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 300px;
  width: 100vw;
  max-width: 600px;
  background: white;
  padding: 2rem;
  border: 10px solid gray;
}
</style>