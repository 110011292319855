<template>
  <div v-if="heat" class="master-judge-sheet container my-5 py-3" :class="isCurrentHeat">
    <div class="d-flex justify-content-between">
      <h2>Heat {{ prettyHeatNumber }} - {{ heatRound }}</h2>
      <div class="d-flex align-items-center">
        <p class="mb-0 mx-2">{{ judgesReady ? 'Judges Ready' : 'Judges Not Ready' }}</p>
        <div class="btn" :class="judgesReady ? 'btn-success' : 'btn-danger'"  @click="endHeat()">{{ buttonMessage }}</div>
      </div>
    </div>
    <div class="mt-2">
      <TeamCard v-for="(team,index) in heat.teams" :heatTeam = "index+1" :heatNumber = "heatNumber" :key = "index" master ="master" judgeID = "0" />
    </div>
    <div class="heat-popup d-flex justify-content-center align-items-center" v-if="heatPopup">
      <div class="container">
        <div class="row text-center align-items-center justify-content-center">
          <div class="heat-popup-inner col-10">
            <h2>Are you sure you want to end the heat?</h2>
            <div class="btn btn-danger mx-1" @click="heatPopup = false">No, Go Back</div>
            <div class="btn btn-success mx-1" @click="advanceHeat()">Yes, End Heat</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TeamCard from '@/components/MasterJudge/TeamCard'
export default {
  name: 'MasterJudgeSheet',
  components: {
    TeamCard
  },
  props: {
    heatNumber: {
      type: [String, Number]
    },
    master: {
      type: [Boolean]
    },
    judgeID: {
      type: [Number, String]
    }
  },
  data() {
    return {
      heatPopup: false
    }
  },
  computed: {
    heat() {
      return this.heats.find( heat => heat.heat_number == this.heatNumber )
    },
    judgesReady() {
      return !this.heat.judges_ready.includes(false)
    },
    heatRound() {
      if (this.heat.round_of == 48) {
        return "Round 1"
      } else if  ( this.heat.round_of == 32 ) {
        return "Round 2"
      } else if ( this.heat.round_of == 16 ) {
        return "Round 3"
      } else if ( this.heat.round_of == 8 ) {
        return "Quarterfinals" 
      } else if ( this.heat.round_of == 4 ) {
        return "Semifinals" 
      } else if ( this.heat.round_of == 2 ) {
        return "Finals" 
      } 
    },
     prettyHeatNumber() {
      if (this.heat.round_of == 48) {
        return this.heatNumber
      } else if  ( this.heat.round_of == 32 ) {
        return this.heatNumber - 16
      } else if ( this.heat.round_of == 16 ) {
        return this.heatNumber - 32
      } else if ( this.heat.round_of == 8 ) {
        return this.heatNumber - 40
      } else if ( this.heat.round_of == 4 ) {
        return this.heatNumber - 44
      } else if ( this.heat.round_of == 2 ) {
        return this.heatNumber - 46
      } 
    },
    buttonMessage() {
      if (this.heatNumber >= this.currentHeat ) {
        return 'End Heat'
      } else {
        return 'Update Heat'
      }
    },
    isCurrentHeat() {
      if ( this.currentHeat <= 16 || this.currentHeat >= 45 ) {
        if ( this.heatNumber == this.currentHeat ) {
          return 'current-heat'
        } else {
          return ''
        }
      } else if ( this.currentHeat >= 17 && this.currentHeat <= 44 ) {
        if ( this.heatNumber == this.currentHeat || this.heatNumber == this.currentHeat + 1 ) {
          return 'current-heat'
        } else {
          return ''
        }
      }
    },
    ...mapState({
      teams: state => state.teams.teams,
      heats: state => state.heats.heats,
      currentHeat: state => state.heats.currentHeat,
      liveHeat: state => state.heats.liveHeat,
      admin: state => state.admin
    })
  },
  methods: {
    endHeat() {
      // advance teams to next heat, check if it is a three team heat or two team heat, advance appropriately
      
      // this stops heats from being ended out of order and messing up bracket math
      if ( this.currentHeat < this.heatNumber ) { 
        alert('You must end the previous heat before ending this one.')
      } else {
        this.heatPopup = true;
      }
    },
    advanceHeat() {
      this.heatPopup = false
      if ( this.heat.teams.length == 3 ) {
        this.endThreeTeamHeat()
      } else if ( this.heat.teams.length == 2 ) {
        this.endTwoTeamHeat()
      }
    },
    async endThreeTeamHeat() {
      // final heat scores for eatch team
      const teamOneScore = this.heat.teams[0].scores.team_score;
      const teamTwoScore = this.heat.teams[1].scores.team_score;
      const teamThreeScore = this.heat.teams[2].scores.team_score;

      // the heat object is going to be updated to add 'advanced:true' to each advancing team
      let finalHeat = this.heat

      // need to adjust the heat and target heats based on whether this heat is the current one, or someone is editing a heat that already ended
      let endedHeat = this.currentHeat;

      if ( this.currentHeat != this.heat.heat_number ) {
        endedHeat = this.heat.heat_number;
      }

      // this is going to sort the three teams in terms of where they placed, and update advanced field to true

      finalHeat.teams.sort(function(a, b) {
        return b.scores.team_score - a.scores.team_score;
      });

      finalHeat.teams[0].advanced = true
      finalHeat.teams[1].advanced = true
      finalHeat.teams[2].advanced = false

      // Determine jersey color 
      let team1Jersey = 'gray'
      let team2Jersey = 'pink'
      if (endedHeat % 2 === 1) {
        team1Jersey = 'green'
        team2Jersey = 'orange'
      }
      // First placed team object to be passed to next heat
      const team1NextHeat = endedHeat + 16
      const team1 = {
        team_id: finalHeat.teams[0].team_id,
        scores: {
            team_score: 0,
            surfer_1: {
              high_score: 0,
              scores: [
                {
                    wave_id: 1,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 2,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 3,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 4,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 5,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 6,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 7,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 8,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 9,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 10,
                    average: 0,
                    scores: [0,0,0]
                  }
              ],
              jersey: team1Jersey
            },
            surfer_2: {
              high_score: 0,
              scores: [
                {
                    wave_id: 1,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 2,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 3,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 4,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 5,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 6,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 7,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 8,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 9,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 10,
                    average: 0,
                    scores: [0,0,0]
                  }
              ],
              jersey: team1Jersey + '_logo'
            }
          },
          advanced: false
      }

      // Second placed team object to be passed to next heat
      let team2NextHeat = endedHeat + 17
      // conditional for preventing last team from surfing back to back
      // 2nd place from heat 9 goes to first heat of round 2, second place from heat 16 goes to heat 26, where 9 would have gone
      if ( endedHeat == 9 ) {
        team2NextHeat = 17
        team2Jersey = 'pink'
      } else if ( endedHeat == 16 ) {
        team2NextHeat = 26
        team2Jersey = 'orange'
      }
      const team2 = {
        team_id: finalHeat.teams[1].team_id,
        scores: {
            team_score: 0,
            surfer_1: {
              high_score: 0,
              scores: [
                {
                    wave_id: 1,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 2,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 3,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 4,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 5,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 6,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 7,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 8,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 9,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 10,
                    average: 0,
                    scores: [0,0,0]
                  }
              ],
              jersey: team2Jersey
            },
            surfer_2: {
              high_score: 0,
              scores: [
                {
                    wave_id: 1,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 2,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 3,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 4,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 5,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 6,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 7,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 8,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 9,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 10,
                    average: 0,
                    scores: [0,0,0]
                  }
              ],
              jersey: team2Jersey + '_logo'
            }
          },
          advanced: false
      }

      // update current heat with who advances
      this.$store.dispatch('updateHeatId', {heat_number: endedHeat, heat: finalHeat})

      // update next round heat for first place team
      this.$store.dispatch('updateHeatTeam', {heat_number: team1NextHeat, team_number: 0, team: team1})

      // update next round heat for second place team
      this.$store.dispatch('updateHeatTeam', {heat_number: team2NextHeat, team_number: 1, team: team2})

      // move to next heat
      if ( this.currentHeat == endedHeat ) {
        await this.$store.dispatch('getNextHeat')
        if ( this.liveHeat < this.currentHeat ) {
          this.$store.dispatch('getNextLiveHeat')
        }
      }
    },
    async endTwoTeamHeat() {
      // final heat scores for eatch team
      const teamOneScore = this.heat.teams[0].scores.team_score;
      const teamTwoScore = this.heat.teams[1].scores.team_score;

      // the heat object is going to be updated to add 'advanced:true' to each advancing team
      let finalHeat = this.heat

       // need to adjust the heat and target heats based on whether this heat is the current one, or someone is editing a heat that already ended
       let endedHeat = this.currentHeat;

        if ( this.currentHeat != this.heat.heat_number ) {
          endedHeat = this.heat.heat_number;
        }

      // this is going to sort the three teams in terms of where they placed, and update advanced field to true

      finalHeat.teams.sort(function(a, b) {
        return b.scores.team_score - a.scores.team_score;
      });

      finalHeat.teams[0].advanced = true
      finalHeat.teams[1].advanced = false
      // update current heat with who advances
      this.$store.dispatch('updateHeatId', {heat_number: endedHeat, heat: finalHeat})
      
      // Jersey colors
      let jerseyColor = 'orange'
      if ( endedHeat % 4 === 1) {
        jerseyColor = 'green'
      } else if ( endedHeat % 4 === 2) {
        jerseyColor = 'pink'
      } else if ( endedHeat % 4 === 3) {
        jerseyColor = 'gray'
      }

      // Setup team object to be passed to next round
      const winningTeam = {
        team_id: finalHeat.teams[0].team_id,
        scores: {
            team_score: 0,
            surfer_1: {
              high_score: 0,
              scores: [
                {
                    wave_id: 1,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 2,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 3,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 4,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 5,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 6,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 7,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 8,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 9,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 10,
                    average: 0,
                    scores: [0,0,0]
                  }
              ],
              jersey: jerseyColor

            },
            surfer_2: {
              high_score: 0,
              scores: [
                {
                    wave_id: 1,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 2,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 3,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 4,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 5,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 6,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 7,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 8,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 9,
                    average: 0,
                    scores: [0,0,0]
                  },
                  {
                    wave_id: 10,
                    average: 0,
                    scores: [0,0,0]
                  }
              ],
              jersey: jerseyColor + '_logo'
            }
          },
          advanced: false
      }

      let nextHeatNumber = Number
      if (endedHeat === 45 || endedHeat === 46) {
        nextHeatNumber = 47
      } else if (endedHeat === 47) {
        nextHeatNumber = 48 // this will trigger an end to the contest
      } else {
        if(finalHeat.round_of === 32) {
          let nextHeatFactor1 = endedHeat + (finalHeat.round_of / 2)
          let nextHeatFactor2 = Math.round((endedHeat - ((finalHeat.round_of / 2) + 1 )) / 2)
          nextHeatNumber = nextHeatFactor1 - nextHeatFactor2
        } else if (finalHeat.round_of === 16) {
          let heatFactor = Math.round(7.5 - ((endedHeat - (32 + 1))/2))
          nextHeatNumber = endedHeat + heatFactor
          console.log(`${heatFactor} and ${nextHeatNumber}`)
        } else if (endedHeat === 41 || endedHeat === 42 ) {
          nextHeatNumber = 45
        } else if (endedHeat === 43 || endedHeat === 44) {
          nextHeatNumber = 46
        } 
      }

      // heat 48 means contest is over
      if(nextHeatNumber != 48) {
        if (endedHeat % 2 === 1) {
          // update next round heat for first place team
          this.$store.dispatch('updateHeatTeam', {heat_number: nextHeatNumber, team_number: 0, team: winningTeam})
        } else {
          // update next round heat for first place team
          this.$store.dispatch('updateHeatTeam', {heat_number: nextHeatNumber, team_number: 1, team: winningTeam})
        }

        if (this.currentHeat == endedHeat ) {
          // move to next heat, only if the current live heat was just ended
          this.$store.dispatch('getNextHeat')
          if ( this.liveHeat < this.currentHeat ) {
            this.$store.dispatch('getNextLiveHeat')
          }
        }
        
      } else {
        console.log('contest is over, winning team is' + winningTeam.team_id)
        this.$store.dispatch('endContest', winningTeam.team_id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.master-judge-sheet {
  background-color: white;
  border: 1px solid gray;
}
.heat-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  color: white;
  .heat-popup-inner {
    background: black;
    padding: 100px;
  }
}

.current-heat {
  border: 10px solid orange;
}
</style>