<template>
  <JudgeHome judgeID=1 />
</template>

<script>
import JudgeHome from '@/components/master/JudgeHome'
export default {
  components: {
    JudgeHome
  }
}
</script>

<style>

</style>