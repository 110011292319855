<template>
  <div class="team-select">
    <div class="mb-2">
      <p>Team ID: {{ team.team_id }}</p>
    </div>
    <div class="d-flex justify-content-start align-items-center">
      <div class="mx-2">
        <label>Surfer 1</label>
        <select v-model="teamModel.surfer_1_id">
          <option v-for="surfer in sortedSurfers" :key="surfer.id" :value="surfer.id">
            {{ surfer.first_name }} {{ surfer.last_name }} - Age {{ surfer.age }}
          </option>
        </select>
      </div>
      <div class="mx-2">
        <label>Surfer 2</label>
        <select v-model="teamModel.surfer_2_id">
          <option v-for="surfer in sortedSurfers" :key="surfer.id" :value="surfer.id">
            {{ surfer.first_name }} {{ surfer.last_name }} - Age {{ surfer.age }}
          </option>
        </select>
      </div>
      <div class="mx-2">
        <div class="btn btn-primary" @click="updateTeam()">Save</div>
      </div>
    </div>  
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    team: {
      type: Object
    }
  },
  data() {
    return {
      teamModel: {
        team_id: 0,
        surfer_1_id: '',
        surfer_2_id: ''
      },
      sortedSurfers: []
    }
  },
  mounted() {
    this.teamModel = this.team
    this.sortSurfers()
  },
  methods: {
    updateTeam() {
      this.$store.dispatch('updateTeam', this.teamModel)
    },
    sortSurfers() {
      this.sortedSurfers = this.surfers.sort((a,b) => {
        let nameA = a.first_name.toLowerCase()
        let nameB = b.first_name.toLowerCase()
        if( nameA < nameB ) {
          return -1
        } else if ( nameA > nameB ) {
          return 1
        } else {
          return 0
        }
      })
    }
  },
  computed: {
    ...mapState({
      surfers: state => state.surfers.surfers,
      teams: state => state.teams.teams
    })
  }
}
</script>

<style lang="scss" scoped>
label, select {
  display: block;
}

.team-select {
  border: 1px solid gray;
  background: white;
  padding: 1rem;
}
</style>