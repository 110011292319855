<template>
  <div class="row mb-4 relative">
    <JerseyColor :color="jerseyColor" @click.native="togglePopup()"/>
    <h3 class="text-center">{{ surferObject.first_name }} {{ surferObject.last_name }}</h3>
    <div class="d-flex justify-content-between best-score">
      <div><p class="mb-1">Best Score:</p></div>
      <div><p class="mb-1">{{ scores.high_score }}</p></div>
    </div>
    <ul class="mt-1">
      <li v-for="(score, index) in scores" class="d-flex justify-content-between score" :key="index + score.average"><div><span>Wave {{score.wave_id}}</span></div> <span>{{ score.average }}</span></li>
    </ul>
    <div class="surfer-modal-overlay" v-if="showPopup" @click="showPopup = false"></div>
    <div class="surfer-modal-inner" v-if="showPopup">
      <figure class="surfer-image">
        <img :src="surferImage">
      </figure>
      <table class="surfer-table">
        <tr>
          <th colspan="2">
            <h4 class="my-0">{{ surferObject.first_name }} {{ surferObject.last_name }}</h4>
          </th>
        </tr>
        <tr>
          <td>
            <p class="mb-1"><strong>Age:</strong> {{ surferObject.age }}</p>
          </td>
          <td>
            <p class="mb-1"><strong>Stance:</strong> {{ surferObject.stance }}</p>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <p class="mb-1"><strong>Hometown:</strong> {{ surferObject.hometown }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="mb-1"><strong>Average Wave Score:</strong> {{ surferObject.avg_score != undefined ? surferObject.avg_score.toFixed(2) : null }}</p>
          </td>
          <td>
            <p class="mb-1"><strong>High Wave Score:</strong> {{ surferObject.high_score }}</p>
          </td>
        </tr>
      </table>
      <p class="my-3 close-text" @click="togglePopup()">CLOSE</p>
    </div>
  </div>
</template>

<script>
import JerseyColor from '@/components/JerseyColor.vue';
import { mapState } from 'vuex'
  export default {
    name: 'HeatScores',
    props: {
      surfer_id: {
        type: [Number, String]
      },
      surferNumber: {
        type: [Number, String]
      },
      heatTeam: {
        type: [Number, String]
      },
      heatNumber: {
        type: [ Number, String ]
      }
    },
    data() {
      return {
        showPopup: false
      }
    },
    components: {
      JerseyColor
    },
    computed: {
      surferImage() {
        if ( this.surferObject.image ) {
          return this.surferObject.image
        } else {
          const imageURL = require('../assets/clam-2024.png')
         return imageURL
        }
      },
      team() {
        return this.teams.find(team => team.team_id == this.currentHeat.teams[this.heatTeam-1].team_id)
      },
      surferObject() {
        let newSurfer = this.surfers.find(surfer => surfer.id == this.surfer_id )
        return newSurfer;
      },
      currentHeat() {
        return this.heats.find(heat => heat.heat_number == this.heatNumber)
      },
      jerseyColor() {
        if(this.surferNumber == 1 ) {
          return this.currentHeat.teams[this.heatTeam-1].scores.surfer_1.jersey
        } else {
          return this.currentHeat.teams[this.heatTeam-1].scores.surfer_2.jersey
        }
      },
      scores() {
        // each heat has two surfers to a team, so this uses the surfer number prop to determine which one to show scores for
        if(this.surferNumber == 1 ) {
          return this.currentHeat.teams[this.heatTeam-1].scores.surfer_1.scores.filter(score => score.average != 0)
        } else {
          return this.currentHeat.teams[this.heatTeam-1].scores.surfer_2.scores.filter(score => score.average != 0)
        }
         
      },
      ...mapState({
        surfers: state => state.surfers.surfers,
        teams: state => state.teams.teams,
        heats: state => state.heats.heats,
        admin: state => state.admin
      })
    },
    methods: {
      togglePopup() {
        this.showPopup = !this.showPopup
      }
    }
  }
</script>

<style lang="scss" scoped>
.updateScore {
  width: 50px;
  margin-bottom: 1rem;
}
input[type=submit] {
  background: #4C78DD;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  padding: .5rem 1.5rem;
  border: none;
}
.best-score {
  font-weight: 700;
  border-bottom: 1px solid #bdbdbd;
}
.add-score {
  width: 140px;
  margin: .5rem auto;
  color: #4C78DD;
  cursor: pointer;
}
.score {
  font-size: .875rem;;
}
h3 {
  font-size:16px; 
}
p {
  font-size: 14px;
}
.delete-icon {
  height: 20px;
  width: auto;
  margin-right: 8px;
}
.relative {
  position: relative;
}
.surfer-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 1;
}
.surfer-modal-inner {
  border: 10px solid #397476;
  z-index: 2;
  position: fixed;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
  width: 500px;
  max-width: 100%;
  height: 500px;
  background: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  h4 {
    color: #397476;
  }
  figure {
    width: 70%;
    height: 250px;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .close-text {
    background: #397476;
    color: #ffffff;
    font-weight: bold;
    border: 2px solid #397476;
    padding: .25rem .5rem;
  }
  table {
    th, td {
      padding: .5rem;
      border: 1px solid #dadada;
    }
    th {
      background: #e8f5f9;
    }
  }
}
</style>