<template>
  <div class="container-fluid dark-bg">
    <div class="row text-center">
      <div class="col-12">
        <img class="clam-jam-logo mt-5" src="@/assets/clam-2024.png" alt="2024 Clam Jam">
      </div>
    </div>
    <h1 class="text-center pt-5 mb-4 text-white">2024 Clam Jam Bracket</h1>
    <p class="text-center small text-white"><em>Click on a heat to see the score details</em></p>
    <div class="text-center text-light my-4">
      <b-btn @click="toggleJerseys()">{{ jerseyMessage }}</b-btn>
    </div>
    <div class="row d-none d-xl-flex">
      <div class="col-12 col-xl-2  text-white">
        <h2>Round 1 (48 teams)</h2>
        <b-list-group v-for="(heat,index) in heats.slice(0,16)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
          <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
          <b-list-group-item class="justify-content-between" :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
            <div class="d-flex">
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
            &nbsp;+&nbsp;  
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
            </div>
            <TeamChange v-if="admin" :heat_number="heat.heat_number" :team_id="team.team_id" :surfers="surfers" :teams="teams" :team_number="index" />
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-12 col-xl-2 text-white">
        <h2>Round 2 (32 teams)</h2>
        <b-list-group v-for="(heat,index) in heats.slice(16,32)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
          <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
          <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
            &nbsp;+&nbsp;  
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-12 col-xl-2 text-white">
        <h2>Round 3 (16 teams)</h2>
        <b-list-group v-for="(heat,index) in heats.slice(32,40)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
          <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
          <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
            &nbsp;+&nbsp;  
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-12 col-xl-2 text-white">
        <h2>Quarterfinals</h2>
        <b-list-group v-for="(heat,index) in heats.slice(40,44)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
          <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
          <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
            &nbsp;+&nbsp;  
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-12 col-xl-2 text-white">
        <h2>Semifinals</h2>
        <b-list-group v-for="(heat,index) in heats.slice(44,46)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
          <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
          <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
            &nbsp;+&nbsp;  
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-12 col-xl-2 text-white">
        <h2>Finals</h2>
        <b-list-group v-for="(heat,index) in heats.slice(46,47)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
          <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
          <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
            &nbsp;+&nbsp;  
            <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div class="d-block d-xl-none">
      <b-tabs pills content-class="mt-3" align="center" justified>
        <b-tab title="Round 1" title-link-class="nowrap-tab">
          <h2 class="text-white">Round 1 (48 teams)</h2>
          <b-list-group v-for="(heat,index) in heats.slice(0,16)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
            <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
            <b-list-group-item  class="justify-content-between" :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
              <div class="d-flex">
                <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
                &nbsp;+&nbsp;  
                <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
              </div>
              <TeamChange v-if="admin" :heat_number="heat.heat_number" :team_id="team.team_id" :surfers="surfers" :teams="teams" :team_number="index" />
            </b-list-group-item>
          </b-list-group>
        </b-tab>
        <b-tab title="Round 2" title-link-class="nowrap-tab">
          <h2 class="text-white">Round 2 (32 teams)</h2>
          <b-list-group v-for="(heat,index) in heats.slice(16,32)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
            <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
            <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
              &nbsp;+&nbsp;  
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
            </b-list-group-item>          
          </b-list-group>
        </b-tab>
        <b-tab title="Round 3" title-link-class="nowrap-tab">
          <h2 class="text-white">Round 3 (16 teams)</h2>
          <b-list-group v-for="(heat,index) in heats.slice(32,40)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
            <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
            <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
              &nbsp;+&nbsp;  
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
            </b-list-group-item>          
          </b-list-group>
        </b-tab>
        <b-tab title="Quarterfinals" title-link-class="nowrap-tab">
          <h2 class="text-white">Quarterfinals</h2>
          <b-list-group v-for="(heat,index) in heats.slice(40,44)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
            <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
            <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
              &nbsp;+&nbsp;  
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
            </b-list-group-item>          
          </b-list-group>
        </b-tab>
        <b-tab title="Semifinals" title-link-class="nowrap-tab">
          <h2 class="text-white">Semifinals</h2>
          <b-list-group v-for="(heat,index) in heats.slice(44,46)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
            <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
            <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
              &nbsp;+&nbsp;  
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
            </b-list-group-item>          
          </b-list-group>
        </b-tab>
        <b-tab title="Finals" title-link-class="nowrap-tab">
          <h2 class="text-white">Finals</h2>
          <b-list-group v-for="(heat,index) in heats.slice(46,47)" :key="'round1_' + index" class="mb-3" @click="showHeatInfo(heat)">
            <b-list-group-item variant="dark" class="justify-content-between"><div>Heat {{ heat.heat_number }}</div> <div class="start-time">{{startTime[heat.heat_number - 1]}}</div></b-list-group-item>
            <b-list-group-item :class="{'jerseys': showJerseys}" v-for="(team,index) in heat.teams" :key="Math.random() * 100" :variant="styleVariant(team)">
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_1.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_1_id" :surfers="surfers" :teams="teams" /></div>
              &nbsp;+&nbsp;  
              <div><JerseyColor v-if="showJerseys" small :color="team.scores.surfer_2.jersey" /><Surfer :team_id="team.team_id" surfer_number="surfer_2_id" :surfers="surfers" :teams="teams" /></div>
            </b-list-group-item>          
          </b-list-group>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal ref="heat-modal" id="modal-2" title="Heat Information" cancelDisabled>
      <div v-if="heatInfo.teams">
        <div v-if="heatInfo.teams[0]">
          <h5>Team 1 Scores</h5>
          <p class="gray-text mb-1"><strong>{{team1_surfer1}}:</strong> <span v-for="score,index in heatInfo.teams[0].scores.surfer_1.scores.filter(score => score.average != 0)" :key="score + index">{{score.average}}, </span></p>
          <p class="gray-text mb-3"><strong>{{team1_surfer2}}:</strong> <span v-for="score,index in heatInfo.teams[0].scores.surfer_2.scores.filter(score => score.average != 0)" :key="score + index">{{score.average}}, </span></p>
        </div>
        <div v-if="heatInfo.teams[1]">
          <h5>Team 2 Scores</h5>
          <p class="gray-text mb-1"><strong>{{team2_surfer1}}:</strong> <span v-for="score,index in heatInfo.teams[1].scores.surfer_1.scores.filter(score => score.average != 0)" :key="score + index">{{score.average}}, </span></p>
          <p class="gray-text mb-3"><strong>{{team2_surfer2}}:</strong> <span v-for="score,index in heatInfo.teams[1].scores.surfer_2.scores.filter(score => score.average != 0)" :key="score + index">{{score.average}}, </span></p>
        </div>
        <div v-if="heatInfo.teams[2]">
          <h5>Team 3 Scores</h5>
          <p class="gray-text mb-1"><strong>{{team3_surfer1}}:</strong> <span v-for="score,index in heatInfo.teams[2].scores.surfer_1.scores.filter(score => score.average != 0)" :key="score + index">{{score.average}}, </span></p>
          <p class="gray-text mb-1"><strong>{{team3_surfer2}}:</strong> <span v-for="score,index in heatInfo.teams[2].scores.surfer_2.scores.filter(score => score.average != 0)" :key="score + index">{{score.average}}, </span></p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import JerseyColor from '@/components/JerseyColor.vue';
import Surfer from '@/components/Surfer.vue'
import TeamChange from '@/components/MasterJudge/TeamChange.vue'
  export default {
    name: 'Bracket',
    components: {
      JerseyColor,
      Surfer,
      TeamChange
    },
    data() {
      return {
        targetTeam: {
          surfer_1: '',
          surfer_2: '',
          team_id: ''
        },
        heatInfo: {},
        team1_surfer1: '',
        team1_surfer2: '',
        team2_surfer1: '',
        team2_surfer2: '',
        team3_surfer1: '',
        team3_surfer2: '',
        showJerseys: false,
        jerseyMessage: 'Show Jersey Colors'
      }
    },
    methods: {
      getTeam(id) {
        // Empty team object as placeholder for undefined heats
        const emptyTeam = {
          team_id: 0,
          surfer_1_id: 0,
          surfer_2_id: 0
        }

        // make sure teams are populated
        if(this.teams.length>0) {
          // return empty team if the team hasn't been set
          if(!id) {
            return emptyTeam
          }
          //otherwise find the team and return
          const team = this.teams.find(team => team.team_id === id)
          return team
        } else {
          return emptyTeam
        }
      },
      styleVariant(team) {
        if(team.advanced) {
          return 'success'
        } else if (team.team_id === 0 ) {
          return 'light'
        } else {
          return ''
        }
      },
      showHeatInfo(heat) {
        if(!this.admin) {
          if(heat.teams[0].team_id === 0 || heat.teams[1].team_id === 0 ) {
            this.heatInfo = {}
          } else {
            this.heatInfo = heat
            this.$refs['heat-modal'].show()
            let team1 = this.teams.find(team => team.team_id === heat.teams[0].team_id)
            let team2 = this.teams.find(team => team.team_id === heat.teams[1].team_id)

            const t1_surfer1 = this.surfers.find(surfer => surfer.id === team1.surfer_1_id)
            const t1_surfer2 = this.surfers.find(surfer => surfer.id === team1.surfer_2_id)
            const t2_surfer1 = this.surfers.find(surfer => surfer.id === team2.surfer_1_id)
            const t2_surfer2 = this.surfers.find(surfer => surfer.id === team2.surfer_2_id)
            
            this.team1_surfer1 = `${t1_surfer1.first_name} ${t1_surfer1.last_name}`
            this.team1_surfer2 = `${t1_surfer2.first_name} ${t1_surfer2.last_name}`

            this.team2_surfer1 = `${t2_surfer1.first_name} ${t2_surfer1.last_name}`
            this.team2_surfer2 = `${t2_surfer2.first_name} ${t2_surfer2.last_name}`

            if(heat.teams.length>2) {
              let team3 = this.teams.find(team => team.team_id === heat.teams[2].team_id)

              const t3_surfer1 = this.surfers.find(surfer => surfer.id === team3.surfer_1_id)
              const t3_surfer2 = this.surfers.find(surfer => surfer.id === team3.surfer_2_id)

              this.team3_surfer1 = `${t3_surfer1.first_name} ${t3_surfer1.last_name}`
              this.team3_surfer2 = `${t3_surfer2.first_name} ${t3_surfer2.last_name}`
            }
          }
        }
      },
      toggleJerseys() {
        if (this.showJerseys) {
          this.showJerseys = false
          this.jerseyMessage = 'Show Jersey Colors'
        } else {
          this.showJerseys = true
          this.jerseyMessage = 'Hide Jersey Colors'
        }
      },
      estStartTime() {
        this.startTime = '8:00 AM'
        return this.startTime
      }
    },
    mounted() {
      if (this.teams.length<1) {
        this.$store.dispatch('getTeams')
      }
      if (this.heats.length <1 ) {
        this.$store.dispatch('getHeats')
      }
      if (this.surfers.length <1 ) {
        this.$store.dispatch('getSurfers')
      }
    },
    computed: {
      showTeam() {
        return true
      },
      admin() {
        return this.$store.state.role == 'Admin'
      },
      ...mapState({
        teams: state => state.teams.teams,
        heats: state => state.heats.heats,
        surfers: state => state.surfers.surfers,
        startTime: state => state.startTime
    })
    }
  }
</script>

<style lang="scss" scoped>
.gray-text {
  color: #585555;
}
.list-group-item {
  display: flex;
  &.jerseys {
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
  }
}
.clam-jam-logo {
    height: auto;
    width: 200px;
  }
h2 {
  font-size: 1.25rem;;
}
.list-group-item {
  cursor: pointer;
  @media screen and (min-width: 1200px) {
    font-size: 13px;
    padding: .5rem;
  }
}
.start-time {
  color: #636363;
}

</style>