<template>
  <div class="team-card p-0" :class="{ 'advanced': currentHeat.teams[heatTeam-1].advanced }">
    <div class="row justify-content-start px-3">
      <template v-if="team">
        <div class="col-12 d-flex justify-content-center my-2">
          <div class="me-4">
            <p><strong>Team Score: {{ currentHeat.teams[ heatTeam - 1 ].scores.team_score }}</strong></p>
          </div>
        </div>
        <div class="col-12">
          <JudgesView :surfer_id="team.surfer_1_id" surferNumber=1 :heatTeam="heatTeam" :heatNumber="heatNumber" :judgeID = "judgeID" />
        </div>
        <div class="col-12">
          <JudgesView :surfer_id="team.surfer_2_id" surferNumber=2 :heatTeam="heatTeam" :heatNumber="heatNumber" :judgeID = "judgeID" />
        </div>
      </template>
      <template v-else>
        <p>Empty Team</p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import JudgesView from '@/components/master/JudgesView.vue';
  export default {
    name: 'TeamCard',
    props: {
      heatTeam: {
        type: [Number, String]
      },
      heatNumber: {
        type: [ Number, String ]
      },
      master: {
        type: [Boolean]
      },
      judgeID: {
        type: [Number, String]
      }
    },
    data() {
      return {
        score: 0,
        teamAdvanced: false
      }
    },
    components: {
      JudgesView
    },
    mounted() {
      this.teamAdvanced = this.currentHeat.teams[this.heatTeam - 1].advanced
    },
    computed: {
      team() {
        // taking the heatTeam prop, which is 1 or 2, and using that to match up to the team_id 
        return this.teams.find(team => team.team_id == this.currentHeat.teams[this.heatTeam-1].team_id)
      },
      currentHeat() {
        return this.heats.find(heat => heat.heat_number == this.heatNumber)
      },
      ...mapState({
        teams: state => state.teams.teams,
        heats: state => state.heats.heats        
      })
    }
  }
</script>

<style lang="scss" scoped>

.team-card {
  background: #EFEFEF;  
  border: 5px solid #4B4B4B;
  &.advanced {
    background: #66d38a;
  }
  .team-score-bar {
    padding: 1rem 1rem .5rem;
    background: #4B4B4B;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.25rem;
  }
  &.winning {
    border: 5px solid #3a9e91;
    .team-score-bar {
      background: #3a9e91;
    }
  }
}

.col-12 {
  overflow-x: scroll;
}

</style>