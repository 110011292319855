<template>
  <div class="container py-5 text-center">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-4">
        <p class="text-white mb-0">Enter Password:</p>
        <b-form>
          <label for="password">Password</label>
          <b-form-input id="password" v-model="password"></b-form-input>
          <b-btn class="mt-4" @click="checkPassword">Login</b-btn>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        password: ''
      }
    },
    beforeMount(){
      if(this.$store.state.admin) {
        alert('You are already logged in')
        this.$router.push({name: 'Home'})
      }
    },
    methods: {
      async checkPassword() {
        await this.$store.dispatch('login', this.password).then((response) => {
          if (response === true) {
            alert('Success! You are now logged in')
            if ( this.password == 'Admin' || this.password == 'Jerm' ) { 
              this.$router.push({name: 'Master'})
            } else if ( this.password == 'Judge1') {
              this.$router.push({name: 'Judge 1'})
            } else if ( this.password == 'Judge2') {
              this.$router.push({name: 'Judge 2'})
            } else if ( this.password == 'Judge3') {
              this.$router.push({name: 'Judge 3'})
            } else if ( this.password == 'Registration') {
              this.$router.push({name: 'Surfers'})
            }
          } else {
            alert('Incorrect Password')
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>