<template>
  <div v-if="surfer" class="surfer" :class="{ 'checked-in' : surfer.checked_in }">{{surfer.first_name }} {{ surfer.last_name }}</div>
</template>

<script>
export default {
  name: 'Surfer',
  props: {
    team_id: [Number, String],
    surfer_number: String,
    surfers: Array,
    teams: Array
  },
  computed: {
    surfer() {
      // make sure teams are populated
      if(this.teams.length>0) {
        //otherwise find the team and return
        if ( this.team_id != 0 ) {
          const team = this.teams.find(team => team.team_id === this.team_id)
          return this.surfers.find(surfer => surfer.id === team[this.surfer_number])
        } else {
          const emptySurfer = {
            checked_in: false,
            first_name: 'TBD',
            last_name: ''
          }
          return emptySurfer
        }
      }
    }
  }
}
</script>

<style>
.surfer {
  opacity: .5;
}
.surfer.checked-in {
  opacity: 1;
}
</style>