<template>
  <div class="container-fluid" v-if="heat">
    <div class="row">
      <div class="col-12 text-center mb-3">
        <h2 class="text-white">Heat {{ currentHeat }}</h2>
        <p class="text-white">Round of {{ heat.round_of }}</p>
      </div>
    </div>
    <div class="row justify-content-around px-3">
      <div class="col-12 col-lg-4 mb-3">
        <TeamCard heatTeam=1 :heatNumber="currentHeat" :class="{ winning : winningTeam == '1_2' || winningTeam == '1_3' }"/>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <TeamCard heatTeam=2 :heatNumber="currentHeat" :class="{ winning : winningTeam == '1_2' || winningTeam == '2_3' }"/>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <TeamCard heatTeam=3 :heatNumber="currentHeat" :class="{ winning : winningTeam == '2_3' || winningTeam == '1_3' }"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex' 
import TeamCard from '@/components/TeamCard.vue';
  export default {
    name: 'ThreeHeatView',
    components: {
      TeamCard
    },
    computed: {
      heat() {
        return this.heats.find(heat => heat.heat_number == this.currentHeat)
      },
      winningTeam() {
        // checks to see which team is winning the heat or if it is tied
        const teamOneScore = this.heat.teams[0].scores.team_score;
        const teamTwoScore = this.heat.teams[1].scores.team_score;
        const teamThreeScore = this.heat.teams[2].scores.team_score;

        if ( teamOneScore > teamThreeScore && teamTwoScore > teamThreeScore ) {
          return '1_2'
        } else if ( teamTwoScore > teamOneScore && teamThreeScore > teamOneScore ) {
          return '2_3'
        } else if ( teamOneScore > teamTwoScore && teamThreeScore > teamTwoScore ) {
          return '1_3'
        }else {
          return 'tied'
        }
      },
      ...mapState({
        teams: state => state.teams.teams,
        heats: state => state.heats.heats,
        currentHeat: state => state.heats.currentHeat,
        admin: state => state.admin
    })
    }
  }
</script>

<style lang="scss">

.end-heat {
  display: block;
  background: rgb(7, 167, 159);
  padding: 1rem 3rem;
  margin-top: 2rem;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: white;
}

.prev-heat {
  display: block;
  background: rgb(77, 77, 77);
  padding: 1rem 3rem;
  margin-top: 2rem;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: white;
}
</style>